import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { isReaderViewUrl } from '../utils/pathnameHelpers';

const useLeaveReadingViewIfOpen = (): (() => void) => {
  const history = useHistory();

  return useCallback(() => {
    if (!isReaderViewUrl(history.location.pathname)) {
      return;
    }
    const parentPath = history.location.pathname.split('/read')[0];
    history.replace(parentPath);
  }, [history]);
};

export default useLeaveReadingViewIfOpen;
