import React, { Suspense, useCallback, useEffect, useMemo, useRef } from 'react';

import { tokenizer } from '../../../shared/filters-compiler/tokenizer';
import { openSplitBySubMenu } from '../../../shared/foreground/cmdPalette';
import { globalState, SplitBySeenValues } from '../../../shared/foreground/models';
import background from '../../../shared/foreground/portalGates/toBackground/singleProcess';
import { createToast } from '../../../shared/foreground/toasts.platform';
import useFilteredViewMangoQuery from '../../../shared/foreground/useFilteredViewMangoQuery';
import getSplitByDefaultValue from '../../../shared/foreground/utils/getSplitByDefaultValue';
import useDocumentLocations from '../../../shared/foreground/utils/useDocumentLocations';
import type { FilteredView } from '../../../shared/types';
import { SplitByKey } from '../../../shared/types';
import { ShortcutId } from '../../../shared/types/keyboardShortcuts';
import useDocumentIdsForDocumentList from '../hooks/useDocumentIdsForDocumentList';
import { useKeyboardShortcutPreventDefault } from '../hooks/useKeyboardShortcut';
import { isReaderViewUrl } from '../utils/pathnameHelpers';
import { useShortcutsMap } from '../utils/shortcuts';
import useLocation from '../utils/useLocation';
import { DeleteAllSeenButton } from './DeleteAllSeenButton';
import styles from './DocumentInbox.module.css';
import DocumentListLoaderSkeleton from './DocumentListLoaderSkeleton';
import Documents from './Documents';
import { FloatingPill } from './FloatingPill';
import InboxHeader from './InboxHeader';
import { MarkAllSeenButton } from './MarkAllSeenButton';

interface FilterViewProps {
  documentPathPrefix: string;
  openDocumentId?: string;
  parentPath: string;
  query?: string;
  splitByUrlParam?: SplitByKey;
  splittingBy?: string;
  splitValue?: string;
  view?: FilteredView;
}

// This component can be used with a saved view or just a query.
export const FilterView = ({
  documentPathPrefix,
  openDocumentId,
  parentPath,
  query: queryParam,
  splitByUrlParam,
  splittingBy,
  splitValue,
  view,
}: FilterViewProps) => {
  const documentLocations = useDocumentLocations();
  const shortcutsMap = useShortcutsMap();
  const { pathname } = useLocation();
  const isReadingView = isReaderViewUrl(pathname);
  const query = view?.query || queryParam || '';
  const { tokens: filterTokens } = useMemo(() => tokenizer(query), [query]);
  const splitBy = view?.splitBy ?? splitByUrlParam ?? splittingBy;
  const isSplitBySeen = splitBy === SplitByKey.Seen;
  const bundledAlreadyLoadedRef = useRef(false);
  const splitValueOrDefault = useMemo(() => {
    if (!splitBy) {
      return undefined;
    }

    if (splitValue) {
      return splitValue;
    }

    return getSplitByDefaultValue(splitBy, documentLocations) ?? undefined;
  }, [documentLocations, splitBy, splitValue]);
  const isSeenTab = isSplitBySeen && splitValueOrDefault === SplitBySeenValues.seen.queryParamValue;
  const isUnseenTab = isSplitBySeen && splitValueOrDefault === SplitBySeenValues.unseen.queryParamValue;
  const persistentStateLoaded = globalState(useCallback((state) => state.persistentStateLoaded, []));

  useEffect(() => {
    async function loadBundle() {
      if (view?.extraData?.bundleId) {
        await background.pollLatestState(10);
      }

      bundledAlreadyLoadedRef.current = true;
    }

    if (!bundledAlreadyLoadedRef.current) {
      loadBundle();
    }
  }, [view?.extraData?.bundleId]);

  const { createMangoQuery, mangoQuery, listId, parserErrorMessage, currentSortRule } =
    useFilteredViewMangoQuery({
      view,
      query,
      splitByUrlParam,
      splittingBy,
      splitValue: splitValueOrDefault,
    });

  useEffect(() => {
    if (!parserErrorMessage) {
      return;
    }

    createToast({
      content: parserErrorMessage,
      category: 'error',
    });
  }, [parserErrorMessage]);

  const {
    documentIds,
    fetchMore,
    pageSize,
    shouldShowSkeleton: shouldShowSkeletonFromDocumentIds,
    totalCount,
  } = useDocumentIdsForDocumentList({
    createMangoQuery,
    openDocumentId,
  });

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.OpenSplitBySubMenu],
    useCallback(() => {
      if (view && !isReadingView) {
        openSplitBySubMenu();
      }
    }, [view, isReadingView]),
    {
      description: 'Split view',
    },
  );

  const shouldShowSkeleton = shouldShowSkeletonFromDocumentIds && !isReadingView;

  return (
    <div
      className={`${styles.inbox} ${openDocumentId ? styles.inboxWithOpenDocument : ''}`}
      id="document-inbox"
    >
      <div className={styles.inboxContainer}>
        {!openDocumentId && persistentStateLoaded && (
          <InboxHeader
            listId={listId}
            savedView={view}
            splitBy={splitBy}
            splitByUrlParam={splitByUrlParam}
            filterQuery={query}
            splitValue={splitValueOrDefault}
            openDocumentId={openDocumentId}
            filterTokens={filterTokens}
            documentIdsQuery={mangoQuery}
          />
        )}
        <Suspense fallback={null}>
          {shouldShowSkeleton ? (
            <DocumentListLoaderSkeleton />
          ) : (
            <Documents
              documentPathPrefix={documentPathPrefix}
              key={parentPath}
              listedDocumentIds={documentIds}
              openDocumentId={openDocumentId}
              parentPath={parentPath}
              currentSortRule={currentSortRule}
              onEndThresholdReached={fetchMore}
              pageSize={pageSize}
            />
          )}
        </Suspense>

        {isUnseenTab && mangoQuery && <MarkAllSeenButton mangoQuery={mangoQuery} />}
        {isSeenTab && mangoQuery && <DeleteAllSeenButton mangoQuery={mangoQuery} />}

        {!shouldShowSkeleton && mangoQuery && Boolean(totalCount) && (
          <FloatingPill>
            <>Count: {totalCount}</>
          </FloatingPill>
        )}
      </div>
    </div>
  );
};
