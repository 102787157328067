import React from 'react';

import Icon from './Icon';

export default function SunIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="SunIcon">
      <svg
        className={className}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 3.75C16.4142 3.75 16.75 4.08579 16.75 4.5V8.5C16.75 8.91421 16.4142 9.25 16 9.25C15.5858 9.25 15.25 8.91421 15.25 8.5V4.5C15.25 4.08579 15.5858 3.75 16 3.75Z" />
        <path d="M12 16C12 17.0609 12.4214 18.0783 13.1716 18.8284C13.9217 19.5786 14.9391 20 16 20C17.0609 20 18.0783 19.5786 18.8284 18.8284C19.5786 18.0783 20 17.0609 20 16C20 14.9391 19.5786 13.9217 18.8284 13.1716C18.0783 12.4214 17.0609 12 16 12C14.9391 12 13.9217 12.4214 13.1716 13.1716C12.4214 13.9217 12 14.9391 12 16Z" />
        <path d="M16.75 23.5C16.75 23.0858 16.4142 22.75 16 22.75C15.5858 22.75 15.25 23.0858 15.25 23.5V27.5C15.25 27.9142 15.5858 28.25 16 28.25C16.4142 28.25 16.75 27.9142 16.75 27.5V23.5Z" />
        <path d="M7.33774 7.33695C7.63058 7.04401 8.10546 7.04392 8.3984 7.33676L11.2274 10.1648C11.5203 10.4576 11.5204 10.9325 11.2276 11.2254C10.9347 11.5184 10.4599 11.5185 10.1669 11.2256L7.33793 8.39761C7.04498 8.10477 7.0449 7.6299 7.33774 7.33695Z" />
        <path d="M21.8331 20.7744C21.5402 20.4815 21.0653 20.4815 20.7724 20.7744C20.4795 21.0673 20.4795 21.5421 20.7724 21.835L23.6014 24.664C23.8943 24.9569 24.3692 24.9569 24.6621 24.664C24.955 24.3711 24.955 23.8962 24.6621 23.6034L21.8331 20.7744Z" />
        <path d="M3.75 16C3.75 15.5858 4.08579 15.25 4.5 15.25H8.5C8.91421 15.25 9.25 15.5858 9.25 16C9.25 16.4142 8.91421 16.75 8.5 16.75H4.5C4.08579 16.75 3.75 16.4142 3.75 16Z" />
        <path d="M23.5 15.25C23.0858 15.25 22.75 15.5858 22.75 16C22.75 16.4142 23.0858 16.75 23.5 16.75H27.5C27.9142 16.75 28.25 16.4142 28.25 16C28.25 15.5858 27.9142 15.25 27.5 15.25H23.5Z" />
        <path d="M11.2275 20.7744C11.5204 21.0673 11.5204 21.5421 11.2275 21.835L8.39849 24.664C8.1056 24.9569 7.63073 24.9569 7.33783 24.664C7.04494 24.3711 7.04494 23.8962 7.33783 23.6034L10.1668 20.7744C10.4597 20.4815 10.9346 20.4815 11.2275 20.7744Z" />
        <path d="M24.662 8.39761C24.9549 8.10477 24.955 7.6299 24.6622 7.33695C24.3693 7.04401 23.8944 7.04392 23.6015 7.33676L20.7725 10.1648C20.4796 10.4576 20.4795 10.9325 20.7723 11.2254C21.0652 11.5184 21.54 11.5185 21.833 11.2256L24.662 8.39761Z" />
      </svg>
    </Icon>
  );
}
