import throttle from 'lodash/throttle';
import { useCallback, useEffect, useRef } from 'react';

const useThrottle = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cb: (...args: any[]) => void,
  delay: number,
  options: {
    extraDependencies?: Parameters<typeof useCallback>[1];
    leading?: boolean;
    trailing?: boolean;
  } = { leading: true, trailing: false },
): ReturnType<typeof useCallback> => {
  const leading = options?.leading === undefined ? true : options.leading;
  const trailing = options?.trailing === undefined ? false : options.trailing;

  // add custom lodash options
  const lodashOptions = { leading, trailing };
  const cbRef = useRef(cb);
  // use mutable ref to make useCallback/throttle not depend on `cb` dep
  useEffect(() => {
    cbRef.current = cb;
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle((...args) => cbRef.current(...args), delay, lodashOptions),
    [delay, ...(options.extraDependencies ?? [])],
  );
};

export default useThrottle;
