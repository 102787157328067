import React from 'react';

import { Dialog } from './Dialog';

export const MarkAllFeedDocumentsAsSeenDialog = ({
  isOpen,
  onConfirm,
  onCancel,
}: { isOpen: boolean; onConfirm: () => void; onCancel: () => void }) => {
  if (isOpen) {
    return (
      <div>
        <Dialog
          id="mark-all-as-seen"
          title="Mark all as seen?"
          subtitle="Are you sure you want to mark all items as seen?"
          actionTitle="Mark as seen"
          cancelTitle="Cancel"
          action={onConfirm}
          cancelAction={onCancel}
        />
      </div>
    );
  }

  return null;
};
