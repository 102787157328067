import React from 'react';

import Icon from './Icon';

export default function TagIcon({
  className = '',
  text = 'Tag',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_467_35)">
          <path
            d="M10 1.66667C5.39763 1.66667 1.66667 5.39763 1.66667 10C1.66667 14.6024 5.39763 18.3333 10 18.3333C14.6024 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39763 14.6024 1.66667 10 1.66667ZM10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_467_35">
            <rect width="20" height="20" fill="currentColor" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}
