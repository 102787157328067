import { Core } from '@pdftron/webviewer';
import React, { useEffect, useState } from 'react';

import { useIsLeftSidebarHidden } from '../hooks/hooks';
import styles from './TableOfContents.module.css';

// Takes an element and generates a table of contents. It will update the content if needed
export default function PdfBookmarks({
  bookmarks,
  onBookmarkClicked,
}: { bookmarks: Core.Bookmark[]; onBookmarkClicked: (pageNum: number) => void }) {
  const leftSidebarHidden = useIsLeftSidebarHidden();
  const [items, setItems] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const items: JSX.Element[] = [];
    const getBookmarkElement = (item: Core.Bookmark, level: number) => {
      const name = item.getName();
      const pageNum = item.getPageNumber();
      const isActive = false;
      const className = `${styles.listItemWrapper} ${isActive ? styles.active : ''} ${
        styles[`listItemLevel${level}`]
      }`;
      const listItemClassName = leftSidebarHidden ? styles.hidden : '';
      const onClick = () => {
        onBookmarkClicked(pageNum);
      };
      const element = (
        <button type="button" onClick={onClick} className={className} key={`pdf-bookmark-${name}`}>
          <div className={styles.bar} />
          <li className={listItemClassName}>{name}</li>
        </button>
      );
      items.push(element);
      item.getChildren().map((b) => getBookmarkElement(b, level + 1));
    };

    bookmarks.forEach((root) => {
      getBookmarkElement(root, 1);
    });

    setItems(items);
  }, [bookmarks, leftSidebarHidden, onBookmarkClicked]);

  return (
    <div className={`${styles.root}`}>
      <h4 id="toc-title" className={`${styles.title} ${leftSidebarHidden ? styles.hidden : ''}`}>
        Contents
      </h4>
      <ol>{items}</ol>
    </div>
  );
}
