import React from 'react';

import Icon from './Icon';

export default function NoUnreadIcon({
  className = '',
  text = 'NoUnread',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="82"
        height="82"
        viewBox="0 0 82 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="40.9998"
          cy="41.0005"
          r="30.0125"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1416 42.1309L36.3679 49.3572L36.3213 49.3105L52.6247 33.0071"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}
