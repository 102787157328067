import { isHTMLElement } from '../../typeValidators';
import getClosestHTMLElement from './getClosestHTMLElement';
import getFirstDescendantWith from './getFirstDescendantWith';
import getFirstElementInViewport from './getFirstElementInViewport';
import getNextElementWithinContainer from './getNextNodeWithinContainer';
import getVisibilityDetails from './getVisibilityDetails';
import isDeepestFocusableElement from './isDeepestFocusableElement';

function getNextClosestFocusableElementToNodeIfItIsntOne({
  container,
  node,
}: {
  container: HTMLElement;
  node?: Node | null;
}): Element | undefined {
  let result: Element | undefined;
  if (node) {
    const element = isHTMLElement(node) ? node : getClosestHTMLElement(node);
    if (element) {
      result = getNextElementWithinContainer({
        container,
        direction: 'next',
        element,
        matcher: isDeepestFocusableElement,
      });
    } else {
      result = undefined;
    }
  } else {
    result = getFirstDescendantWith(container, isDeepestFocusableElement);
  }
  return result;
}

const getFocusedElementOnScroll = async (
  contentRoot: HTMLDivElement,
  scrollableRoot: HTMLElement,
  focusTarget?: Element | HTMLElement,
) => {
  if (!contentRoot) {
    return;
  }

  if (focusTarget) {
    // Otherwise the additional frames add up and it's too slow
    const canCauseReflow = true;
    // Decide when we should automatically re-set the focus indicator to the top of our target screen range:
    const { isTopInView } = await getVisibilityDetails({
      subject: focusTarget,
      scrollableRoot,
      canCauseReflow,
    });
    if (isTopInView) {
      return;
    }
  }

  // Figure out which element is at the top of (or at least in) our target range
  const descendant = await getFirstElementInViewport({
    element: contentRoot,
    scrollableRoot,
  });

  return getNextClosestFocusableElementToNodeIfItIsntOne({ container: contentRoot, node: descendant });
};

export default getFocusedElementOnScroll;
