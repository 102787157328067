import { useEffect, useRef, useState } from 'react';

// https://usehooks.com/useDebounce
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useDebounce(value: any, delay: number, delayToforceUpdateAfter?: number): any {
  const shouldForceUpdate = useRef(false);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (!delayToforceUpdateAfter) {
      return;
    }

    const handler = setInterval(() => {
      shouldForceUpdate.current = true;
    }, delayToforceUpdateAfter);

    return () => {
      clearInterval(handler);
    };
  }, [delayToforceUpdateAfter]);

  useEffect(() => {
    if (shouldForceUpdate.current) {
      setDebouncedValue(value);
      shouldForceUpdate.current = false;
    }

    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
