import getNumericCssPropertyValue from './getNumericCssPropertyValue';
import getUnsafeWindowHeight from './getUnsafeWindowHeight';

// Exludes TTS player if visible
export default function getSafeWindowHeight() {
  return Math.max(
    0,
    getUnsafeWindowHeight() - getNumericCssPropertyValue('--js__tts-player-current-height'),
  );
}
