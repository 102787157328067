import React from 'react';

import Icon from './Icon';

export default function ArticlesIconHeader({
  className = '',
  text = 'Articles',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.7358 5.42156C16.3874 6.11661 16.75 7.03362 16.75 7.98634V15C16.75 17.0711 15.0711 18.75 13 18.75H7C4.92893 18.75 3.25 17.0711 3.25 15L3.25 5C3.25 2.92893 4.92893 1.25 7 1.25L10.2003 1.25C11.2368 1.25 12.2271 1.67903 12.9361 2.43522L15.7358 5.42156ZM15.25 7.98634C15.25 7.41471 15.0324 6.8645 14.6415 6.44748L11.8418 3.46113C11.4164 3.00742 10.8222 2.75 10.2003 2.75L7 2.75C5.75736 2.75 4.75 3.75736 4.75 5L4.75 15C4.75 16.2426 5.75736 17.25 7 17.25H13C14.2426 17.25 15.25 16.2426 15.25 15V7.98634Z" />
        <path d="M6.25 14C6.25 13.5858 6.58579 13.25 7 13.25H13C13.4142 13.25 13.75 13.5858 13.75 14C13.75 14.4142 13.4142 14.75 13 14.75H7C6.58579 14.75 6.25 14.4142 6.25 14Z" />
        <path d="M6.25 11C6.25 10.5858 6.58579 10.25 7 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11C13.75 11.4142 13.4142 11.75 13 11.75H7C6.58579 11.75 6.25 11.4142 6.25 11Z" />
      </svg>
    </Icon>
  );
}
