import React from 'react';

import Icon from './Icon';

export default function StrokePlusIcon({
  text = 'plus',
  ...extraProps
}: {
  text?: string;
} & React.HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span {...extraProps}>
      <Icon text={text}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.75 6.5C10.75 6.08579 10.4142 5.75 10 5.75C9.58579 5.75 9.25 6.08579 9.25 6.5V9.25L6.5 9.25C6.08579 9.25 5.75 9.58579 5.75 10C5.75 10.4142 6.08579 10.75 6.5 10.75H9.25V13.5C9.25 13.9142 9.58579 14.25 10 14.25C10.4142 14.25 10.75 13.9142 10.75 13.5V10.75H13.5C13.9142 10.75 14.25 10.4142 14.25 10C14.25 9.58579 13.9142 9.25 13.5 9.25L10.75 9.25V6.5Z"
            fill="black"
          />
        </svg>
      </Icon>
    </span>
  );
}
