import { useCallback } from 'react';

import { globalState } from '../models';

export default function useRSSFeedIdByUrl(feedUrl: string | undefined) {
  return globalState(
    useCallback(
      (state) => {
        if (!feedUrl || !state.persistent.rssFeeds) {
          return;
        }
        for (const key in state.persistent.rssFeeds) {
          if (state.persistent.rssFeeds[key].url === feedUrl) {
            return key;
          }
        }
      },
      [feedUrl],
    ),
  );
}
