import React, { useEffect, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useLiveValueRef from '../../../shared/foreground/utils/useLiveValueRef';
import { DocumentLocation, FeedDocumentLocation, FilteredView } from '../../../shared/types';
import { DocumentInbox } from './DocumentInbox';
import { FilterView } from './FilterView';

export const InboxPage = () => {
  const {
    openDocumentId: incomingOpenDocumentId,
    documentLocation: filterByDocumentLocation,
    feedDocumentLocation: filterByFeedDocumentLocation,
    uncategorizedDocumentIdToOpen,
  } = useParams<{
    openDocumentId?: string;
    documentLocation?: DocumentLocation;
    feedDocumentLocation?: FeedDocumentLocation;
    uncategorizedDocumentIdToOpen?: string;
  }>();
  const history = useHistory<
    | {
        documentPathPrefix?: string;
        filteredView?: FilteredView;
        parentPath?: string;
      }
    | undefined
  >();
  const locationRef = useLiveValueRef(history.location);
  const lastSeenFilteredViewFromLocationStateRef = useRef(history.location.state?.filteredView);

  const { documentPathPrefix, parentPath } = useMemo(() => {
    if (locationRef.current.state?.parentPath) {
      return {
        documentPathPrefix: locationRef.current.state.documentPathPrefix || '',
        parentPath: locationRef.current.state.parentPath,
      };
    }

    const result = `/${[filterByDocumentLocation, filterByFeedDocumentLocation]
      .filter(Boolean)
      .join('/')}`;
    return {
      documentPathPrefix: result,
      parentPath: result,
    };
  }, [locationRef, filterByFeedDocumentLocation, filterByDocumentLocation]);

  useEffect(() => {
    if (!history.location.state?.filteredView) {
      return;
    }

    lastSeenFilteredViewFromLocationStateRef.current = history.location.state.filteredView;
  }, [history.location.state?.filteredView]);

  if (
    history.location.pathname.startsWith('/read/') &&
    lastSeenFilteredViewFromLocationStateRef.current
  ) {
    return (
      <FilterView
        documentPathPrefix={documentPathPrefix}
        openDocumentId={uncategorizedDocumentIdToOpen ?? incomingOpenDocumentId}
        parentPath={parentPath}
        view={lastSeenFilteredViewFromLocationStateRef.current}
      />
    );
  }

  return (
    <DocumentInbox
      documentPathPrefix={documentPathPrefix}
      filterByFeedDocumentLocation={filterByFeedDocumentLocation}
      filterByDocumentLocation={filterByDocumentLocation}
      incomingOpenDocumentId={incomingOpenDocumentId}
      parentPath={parentPath}
      uncategorizedDocumentIdToOpen={uncategorizedDocumentIdToOpen}
    />
  );
};
