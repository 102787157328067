import React, { useCallback, useMemo } from 'react';

import { globalState } from '../../../shared/foreground/models';
import type { FirstClassDocument } from '../../../shared/types';
import playOrStopTtsFromCurrentScrollPosition from '../utils/playOrStopTtsFromCurrentScrollPosition';
import Button from './Button';
import PlayIcon from './icons/PlayIcon';
import StopIcon from './icons/StopIcon';
import styles from './TtsTriggerButton.module.css';

export default function TtsTriggerButton({
  docId,
}: {
  docId: FirstClassDocument['id'];
}) {
  const documentIdPlayerIsOpenFor = globalState((state) => state.tts?.playingDocId);
  const isPlayerOpenForCurrentDocument = useMemo(
    () => documentIdPlayerIsOpenFor === docId,
    [docId, documentIdPlayerIsOpenFor],
  );

  const playOrStopDocument = useCallback(async () => {
    await playOrStopTtsFromCurrentScrollPosition({
      currentlyOpenDocId: docId,
    });
  }, [docId]);

  let icon: JSX.Element;
  let text: string;
  if (isPlayerOpenForCurrentDocument) {
    icon = <StopIcon className={styles.icon} text="" />;
    text = 'Stop';
  } else {
    icon = <PlayIcon className={styles.icon} text="" />;
    text = 'Listen';
  }

  return (
    <Button className={styles.button} onClick={playOrStopDocument} variant="secondary">
      {icon}
      {text}
    </Button>
  );
}
