import { FocusEventHandler, MutableRefObject, useCallback } from 'react';

import useStatePlusLiveValueRef from '../../../shared/foreground/utils/useStatePlusLiveValueRef';

export default (): {
  isFocused: boolean;
  isFocusedRef: MutableRefObject<boolean>;
  onBlur: FocusEventHandler<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  onFocus: FocusEventHandler<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
} => {
  const [isFocused, setIsFocused, isFocusedRef] = useStatePlusLiveValueRef<boolean>(false);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);
  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);

  return { isFocused, isFocusedRef, onBlur, onFocus };
};
