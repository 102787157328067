import { updateState } from '../../models';

export const setIsDocMoreActionsDropdownOpen = async (val: boolean): Promise<void> => {
  await updateState(
    (state) => {
      state.isDocMoreActionsDropdownOpen = val;
    },
    {
      eventName: 'is-dropdown-open-updated',
      userInteraction: 'unknown',
      isUndoable: false,
    },
  );
};

export const setIsNotebookDropdownOpen = async (val: boolean): Promise<void> => {
  await updateState(
    (state) => {
      state.isNotebookDropdownOpen = val;
    },
    {
      eventName: 'is-notebook-dropdown-open-updated',
      userInteraction: 'unknown',
      isUndoable: false,
    },
  );
};

export const toggleIsDocMoreActionsDropdownOpen = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.isDocMoreActionsDropdownOpen = !state.isDocMoreActionsDropdownOpen;
    },
    {
      eventName: 'is-dropdown-open-toggled',
      userInteraction: 'unknown',
      isUndoable: false,
    },
  );
};
