import { type AnyDocument, type SidebarContentType } from '../../../../types';
import { type StateUpdateOptionsWithoutEventName } from '../../../types';
import { updateDocument } from './update';

export async function setPDFZoomLevel(
  docId: AnyDocument['id'],
  zoom: number,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> {
  await updateDocument(
    docId,
    (doc) => {
      let properZoom = zoom;
      if (properZoom < 0.1) {
        properZoom = 0.1;
      }
      if (properZoom > 14) {
        properZoom = 14;
      }

      if (!doc?.source_specific_data?.pdf) {
        doc.source_specific_data = {
          ...(doc?.source_specific_data ?? {}),
          pdf: {
            ...(doc?.source_specific_data?.pdf ?? {}),
            zoom: properZoom,
          },
        };
      } else {
        doc.source_specific_data.pdf.zoom = properZoom;
      }
    },
    { ...options, eventName: 'pdf-zoom-updated' },
  );
}

export async function setPDFRotation(
  docId: AnyDocument['id'],
  rotation: number,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> {
  await updateDocument(
    docId,
    (doc) => {
      if (!doc?.source_specific_data?.pdf) {
        doc.source_specific_data = {
          ...(doc?.source_specific_data ?? {}),
          pdf: {
            ...(doc?.source_specific_data?.pdf ?? {}),
            rotation,
          },
        };
      } else {
        doc.source_specific_data.pdf.rotation = rotation;
      }
    },
    { ...options, eventName: 'pdf-rotation-updated' },
  );
}

export async function setPDFSidebarContentType(
  docId: AnyDocument['id'],
  sidebarContentType: SidebarContentType,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> {
  await updateDocument(
    docId,
    (doc) => {
      if (!doc?.source_specific_data?.pdf) {
        doc.source_specific_data = {
          ...(doc?.source_specific_data ?? {}),
          pdf: {
            ...(doc?.source_specific_data?.pdf ?? {}),
            sidebarContentType,
          },
        };
      } else {
        doc.source_specific_data.pdf.sidebarContentType = sidebarContentType;
      }
    },
    { ...options, eventName: 'pdf-sidebar-content-type-updated' },
  );
}

export async function togglePDFHtmlViewForDocument(
  docId: AnyDocument['id'],
  viewAsHtml: boolean,
  userInteraction = 'unknown',
): Promise<void> {
  await updateDocument(
    docId,
    (doc) => {
      if (!doc?.source_specific_data?.pdf) {
        doc.source_specific_data = {
          ...(doc?.source_specific_data ?? {}),
          pdf: {
            ...(doc?.source_specific_data?.pdf ?? {}),
            viewAsHtml,
          },
        };
      } else {
        doc.source_specific_data.pdf.viewAsHtml = viewAsHtml;
      }
    },
    {
      eventName: 'pdf-html-view-for-document-toggled',
      userInteraction,
    },
  );
}
