import React, { useEffect, useState } from 'react';

import getSafeWindowHeight from '../utils/getSafeWindowHeight';
import styles from './DocumentListLoaderSkeleton.module.css';

export default function DocumentListLoaderSkeleton({ delay = 100 }: { delay?: number }) {
  const itemHeight = 112;
  const numberOfSkeletonsInScreen = Math.floor(getSafeWindowHeight() / itemHeight) + 1;

  const [delayFinished, setDelayFinished] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelayFinished(true);
    }, delay);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [delay]);

  if (!delayFinished) {
    return null;
  }
  return (
    <>
      {new Array(numberOfSkeletonsInScreen).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <DocumentItemLoaderSkeleton key={index} index={index} />
      ))}
    </>
  );
}

function DocumentItemLoaderSkeleton({ index }: { index: number }) {
  const animationDelay = index * 0.09;

  return (
    <div style={{ animationDelay: `${animationDelay}s` }} className={styles.item}>
      <div className={styles.photo} />
      <div className={styles.linesWrapper}>
        <div className={styles.line} style={{ width: '40%' }} />
        <div className={styles.line} style={{ marginTop: '16px', width: '70%' }} />
        <div className={styles.line} style={{ marginTop: '16px', width: '65%' }} />
      </div>
    </div>
  );
}
