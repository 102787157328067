import React from 'react';

import { Dialog } from './Dialog';

export const DeleteAllFeedSeenDialog = ({
  isOpen,
  onConfirm,
  onCancel,
}: { isOpen: boolean; onConfirm: () => void; onCancel: () => void }) => {
  if (isOpen) {
    return (
      <div>
        <Dialog
          id="delete-all-feed-seen"
          title="Delete all seen items?"
          subtitle="Are you sure you want to delete all seen items? If you've tagged, annotated, or highlighted any items, you'll be given an opportunity to skip those."
          actionTitle="Delete all"
          cancelTitle="Cancel"
          redActionButton
          action={onConfirm}
          cancelAction={onCancel}
        />
      </div>
    );
  }

  return null;
};
