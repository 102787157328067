import getFirstElementIntersectingRect from './getFirstElementIntersectingRect';

// If in scrollable element, set viewportTop to the scrollable element's (bounding client rect) top
const getFirstElementInViewport = async ({
  element,
  scrollableRoot,
}: {
  element: HTMLElement;
  scrollableRoot: HTMLElement;
}): Promise<HTMLElement | null> => {
  return getFirstElementIntersectingRect({
    element,
    // We only care about vertical position
    rect: new DOMRect(Number.MIN_SAFE_INTEGER, 0, Infinity, Infinity),
    scrollableRoot,
  });
};

export default getFirstElementInViewport;
