import React from 'react';

import Icon from './Icon';

export default function SortDownIcon({
  className = '',
  text = 'SortDown',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.75 4.5332C10.75 4.11899 10.4142 3.7832 10 3.7832C9.58579 3.7832 9.25 4.11899 9.25 4.5332V14.3603C8.6969 13.8375 8.21731 13.3027 7.58029 12.5248C7.31785 12.2043 6.84532 12.1573 6.52485 12.4197C6.20437 12.6822 6.15732 13.1547 6.41976 13.4752C7.52846 14.8291 8.2219 15.5237 9.52763 16.5825C9.80298 16.8058 10.1971 16.8058 10.4724 16.5825C11.7784 15.5235 12.4702 14.8289 13.58 13.4756C13.8426 13.1553 13.7959 12.6827 13.4756 12.42C13.1553 12.1574 12.6827 12.2041 12.4201 12.5244C11.7821 13.3025 11.3026 13.8373 10.75 14.36V4.5332Z" />
      </svg>
    </Icon>
  );
}
