import { useEffect, useRef } from 'react';

import type { FirstClassDocument, ReadingPosition } from '../../types';
import { isReadingState } from '../models';
import {
  updateReadingPosition,
  updateScrollPosition,
} from '../stateUpdaters/persistentStateUpdaters/documents/progressRelated';

type UpdateReadingProgress = (pos: ReadingPosition, scrollY: number) => void;

type TrackWords = {
  track: () => number;
  updateReadingProgress: UpdateReadingProgress;
};

const getWordsPerMinuteCalculator = (initialScrollY: number) => {
  let lastScrollPosArray = new Array(50).fill(initialScrollY);
  let totalWords = 0;
  let wordsPerMinute = 0;

  const calculate = (
    wordCount: number,
    windowHeight: number,
    contentHeight: number,
    currentScrollOffsetY: number,
  ) => {
    const wordsOnScreen = wordCount > 0 ? (windowHeight / contentHeight) * wordCount : 0;
    lastScrollPosArray.push(currentScrollOffsetY);

    // A new scroll value has been calculated
    // Figure out the amount of words we have seen because of this scroll
    // If its zero, set the number of words to -1 (at a rate of 200WPM, a person reads about 1 word per 300MS) (200words/60 = 3 words per second / 3 = 1 word per 300MS)

    const l = lastScrollPosArray.length;
    const delta = Math.abs(lastScrollPosArray[l - 2] - lastScrollPosArray[l - 1]);
    let words = (delta / windowHeight) * wordsOnScreen;
    if (words === 0) {
      words = -1;
    }

    // Add that word amount to the total words count
    // Total words are ALL the words we have seen in the last 30 seconds, -3 per second while staying stationary
    totalWords += words;

    // Each time we log a new scroll position, remove the scroll position from 30 seconds ago
    // Subtract this total from the totalWords seen in the last 30 seconds
    if (lastScrollPosArray.length > 100) {
      const lastDelta = Math.abs(lastScrollPosArray[0] - lastScrollPosArray[1]);
      const lastWords = (lastDelta / windowHeight) * wordsOnScreen;
      totalWords -= lastWords;
      lastScrollPosArray = lastScrollPosArray.slice(1);
    }

    // Make sure we never go negative, otherwise things are weird
    if (totalWords < 0) {
      totalWords = 0;
    }
    // Since totalWords is the amount of words we saw in the last 30 seconds, we can compute the
    // Words per minute at this specific point in time
    wordsPerMinute = (totalWords / (lastScrollPosArray.length * 0.3)) * 30;
    return wordsPerMinute;
  };

  return calculate;
};

let updateReadingPositionTimer: number;
let updateScrollPositionTimer: number;
export const trackWordsPerMinute = ({
  docId,
  contentHeight,
  windowHeight,
  wordCount,
  initialScrollPosition,
  maybeTransformPosition,
  wpmThreshold,
  isReadingEnabled,
}: {
  docId: FirstClassDocument['id'];
  contentHeight: number;
  windowHeight: number;
  wordCount: number;
  initialScrollPosition: number;
  maybeTransformPosition: (position: ReadingPosition) => Promise<ReadingPosition | undefined>;
  wpmThreshold: number;
  isReadingEnabled: boolean;
}): TrackWords => {
  let isReading = Boolean(isReadingEnabled);
  isReadingState.setState({ isReading });
  let wordsPerMinute = 0;
  let _currentScrollOffsetY = initialScrollPosition;
  let _internalReadingPosition: ReadingPosition;

  // Use this ID to track latest async call for either update function
  let currentUpdateScrollPositionCallbackId = 0;
  let currentUpdateReadingPositionCallbackId = 0;

  const updateInternalScrollOffsetY = (newOffsetY: number) => {
    _currentScrollOffsetY = newOffsetY;
  };
  const updateInternalReadingPosition = (newPos: ReadingPosition) => {
    _internalReadingPosition = newPos;
  };

  const wpmCalculator = getWordsPerMinuteCalculator(initialScrollPosition);

  const track = () => {
    // This function tracks the seen WPM and decides if we are in skimming or reading mode
    // If we pass a certain threshold of WPM in a specific time, we trigger skimming
    // if that value reduces to a "reading" threshold, we switch to reading
    return setInterval(() => {
      wordsPerMinute = wpmCalculator(wordCount, windowHeight, contentHeight, _currentScrollOffsetY);
      if (isReadingEnabled) {
        if (wordsPerMinute > wpmThreshold) {
          isReading = false;
          isReadingState.setState({ isReading: false }, false);
        } else if (!isReading) {
          // Triggered when we switch from skimming back to reading
          isReading = true;
          isReadingState.setState({ isReading: true }, false);
          currentUpdateReadingPositionCallbackId += 1;
          currentUpdateScrollPositionCallbackId += 1;
          updateReadingPositionCallback(_internalReadingPosition);
        }
      }
    }, 300) as unknown as number;
  };

  const updateScrollPositionCallback = async (newPosition: ReadingPosition) => {
    const currentId = currentUpdateScrollPositionCallbackId;
    // This function allows the user of the useReadingProgressTracking hook to change/add data to the scroll position
    // right before a DB write
    const readingPosition = await maybeTransformPosition(newPosition);
    // Check for outdated calls caused by slow getReadingPosition execution
    if (currentId !== currentUpdateScrollPositionCallbackId || !readingPosition) {
      return;
    }

    await updateScrollPosition(docId, readingPosition, {
      errorMessageIfNothingFound: false,
      eventName: 'document-scroll-position-updated',
      userInteraction: 'scroll',
      isUndoable: false,
    });
  };
  const updateReadingPositionCallback = async (newPosition: ReadingPosition) => {
    const currentId = currentUpdateReadingPositionCallbackId;
    // This function allows the user of the useReadingProgressTracking hook to change/add data to the scroll position
    // right before a DB write
    const readingPosition = await maybeTransformPosition(newPosition);
    // Check for outdated calls caused by slow getReadingPosition execution
    if (currentId !== currentUpdateReadingPositionCallbackId || !readingPosition) {
      return;
    }

    if (isReading && isReadingEnabled) {
      await updateReadingPosition(docId, readingPosition, {
        errorMessageIfNothingFound: false,
        eventName: 'document-progress-position-updated',
        isUndoable: false,
        userInteraction: 'scroll',
      });
    }
  };

  /*
    This is the meat of this hook
    This function is returned from the useReadingProgressTracking hook and is called whenever the
    caller wants to update reading progress (like a scroll event just ended)

    Within here, we update the scroll position first, and then the reading position.
    the reading position is delayed a bit to make sure we truly are reading and not skimming

    The updatePosition callbacks both utilize a function called getReadingPosition, where they
   */
  const updateReadingProgress = (newReadingPosition: ReadingPosition, scrollY: number) => {
    updateInternalScrollOffsetY(scrollY);
    updateInternalReadingPosition(newReadingPosition);
    if (updateReadingPositionTimer) {
      clearTimeout(updateReadingPositionTimer);
    }
    if (updateScrollPositionTimer) {
      clearTimeout(updateScrollPositionTimer);
    }
    currentUpdateReadingPositionCallbackId += 1;
    currentUpdateScrollPositionCallbackId += 1;
    if (newReadingPosition) {
      // Scroll position can be updated much faster but due to server load, we throttle this for now
      updateScrollPositionTimer = setTimeout(
        () => updateScrollPositionCallback(newReadingPosition),
        2000,
      ) as unknown as number;
      // Set the timeout to 2000ms to allow a bit of buffer for user
      // to enter skimming mode without accidentally updating reading progress too early
      updateReadingPositionTimer = setTimeout(
        () => updateReadingPositionCallback(newReadingPosition),
        2000,
      ) as unknown as number;
    }
  };

  return {
    track,
    updateReadingProgress,
  };
};

export const useReadingProgressTracking = ({
  docId,
  contentHeight,
  windowHeight,
  wordCount,
  initialScrollOffset,
  transformReadingPosition,
  wpmThreshold,
  isEnabled = true,
}: {
  docId: FirstClassDocument['id'];
  contentHeight: number;
  windowHeight: number;
  wordCount: number;
  initialScrollOffset: { value: number };
  transformReadingPosition?: (readingPosition: ReadingPosition) => Promise<ReadingPosition | undefined>;
  wpmThreshold: number;
  isEnabled?: boolean;
}) => {
  // Keep track of the latest updateProgress function in this ref
  const updateReadingProgressRef = useRef<UpdateReadingProgress>((pos, scrollY) => undefined);

  useEffect(() => {
    // if no reading transform function is passed, use default function
    const defaultTransformPositionFunction = (position: ReadingPosition) => Promise.resolve(position);

    const { track, updateReadingProgress } = trackWordsPerMinute({
      docId,
      contentHeight,
      windowHeight,
      wordCount: wordCount || 0,
      initialScrollPosition: initialScrollOffset.value,
      maybeTransformPosition: transformReadingPosition ?? defaultTransformPositionFunction,
      wpmThreshold,
      isReadingEnabled: isEnabled,
    });
    const timer = track();
    updateReadingProgressRef.current = updateReadingProgress;

    return () => {
      clearInterval(timer);
      if (updateReadingPositionTimer) {
        clearTimeout(updateReadingPositionTimer);
      }
      if (updateScrollPositionTimer) {
        clearTimeout(updateScrollPositionTimer);
      }
    };
  }, [
    docId,
    wordCount,
    contentHeight,
    windowHeight,
    initialScrollOffset,
    transformReadingPosition,
    wpmThreshold,
    isEnabled,
  ]);

  return updateReadingProgressRef.current;
};
