import escapeStringRegexp from 'escape-string-regexp';

const aliases = {
  /* eslint-disable @typescript-eslint/naming-convention */
  // See https://github.com/JohannesKlauss/react-hotkeys-hook/issues/947
  '`': ['INTLBACKSLASH'],
  '[': ['BRACKETLEFT'],
  '-': ['MINUS'],
  '=': ['EQUAL'],
  /* eslint-enable @typescript-eslint/naming-convention */
};

/*
  Example: we replace INTLBACKSLASH with ` in the UI after the user records a shortcut
  The keys are what we want (the typical name for it); values are the bad values we never want to see.
  Case-insensitive.
*/
export default aliases;

export function replaceBadKeyAliases(input: string): string {
  let result = input;

  for (const [typicalName, badValues] of Object.entries(aliases)) {
    result = result.replace(
      new RegExp(`(${badValues.map((badValue) => escapeStringRegexp(badValue)).join('|')})`, 'gi'),
      typicalName,
    );
  }

  return result;
}
