import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { usePersistentPdfSettings } from '../../../../shared/foreground/stateHooks';
import { setPDFZoomLevel } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/pdf';
import { AnyDocument } from '../../../../shared/types';
import { ShortcutId } from '../../../../shared/types/keyboardShortcuts';
import { useKeyboardShortcut, useKeyboardShortcutPreventDefault } from '../../hooks/useKeyboardShortcut';
import { useShortcutsMap } from '../../utils/shortcuts';
import Button from '../Button';
import ChevronDownIcon from '../icons/ChevronDownSmallIcon';
import NumberInput from '../NumberInput';
import { PdfTronContext } from '../PDFViewer';
import styles from './DocZoomDropdown.module.css';
import { Dropdown, DropdownOptionType } from './Dropdown';

const zoomSteps: number[] = [];

for (let i = 10; i <= 400; i += 10) {
  zoomSteps.push(i);
}

export default function DocZoomDropdown({ docId }: { docId: AnyDocument['id'] }) {
  const [currentZoom, setCurrentZoom] = useState(1);
  const pdfSettings = usePersistentPdfSettings(docId);
  const { documentViewer } = useContext(PdfTronContext);
  const [isOpen, setIsOpen] = useState(false);
  const shortcutsMap = useShortcutsMap();

  useEffect(() => {
    setCurrentZoom(pdfSettings.zoom);
  }, [pdfSettings.zoom]);

  const displayValue = useMemo(() => {
    return Math.round(currentZoom * 100);
  }, [currentZoom]);

  const onChange = useCallback(
    (newZoom: number) => {
      if (!documentViewer) {
        return;
      }

      setPDFZoomLevel(docId, newZoom / 100, { userInteraction: 'unknown' });
    },
    [docId, documentViewer],
  );

  const zoomDocumentIn = useCallback(() => {
    if (!documentViewer) {
      return;
    }

    const nextZoom = zoomSteps.find((step) => step > Math.round(currentZoom * 100));

    if (nextZoom) {
      setPDFZoomLevel(docId, nextZoom / 100, { userInteraction: 'click' });
    }
  }, [docId, currentZoom, documentViewer]);

  const zoomDocumentOut = useCallback(() => {
    if (!documentViewer) {
      return;
    }

    const previousZoom = zoomSteps
      .slice()
      .reverse()
      .find((step) => step < Math.round(currentZoom * 100));

    if (previousZoom) {
      setPDFZoomLevel(docId, previousZoom / 100, { userInteraction: 'click' });
    }
  }, [docId, currentZoom, documentViewer]);

  const resetZoom = useCallback(() => {
    if (!documentViewer) {
      return;
    }
    const zoom = 1;
    setPDFZoomLevel(docId, zoom, { userInteraction: 'click' });
  }, [docId, documentViewer]);

  useKeyboardShortcut(shortcutsMap[ShortcutId.PdfZoomIn], zoomDocumentIn, {
    description: 'Zoom PDF in',
  });

  useKeyboardShortcut(shortcutsMap[ShortcutId.PdfZoomOut], zoomDocumentOut, {
    description: 'Zoom PDF out',
  });

  useKeyboardShortcutPreventDefault(shortcutsMap[ShortcutId.PdfZoomReset], resetZoom, {
    description: 'Reset PDF zoom',
  });

  const options = useMemo(
    () => [
      {
        type: DropdownOptionType.Node,
        node: (
          <div className={styles.zoomStepsWrapper}>
            <NumberInput
              current={displayValue}
              steps={zoomSteps}
              onChange={onChange}
              suffix="%"
              alwaysShowButtons
            />
          </div>
        ),
      },
      {
        type: DropdownOptionType.Separator,
      },
      {
        type: DropdownOptionType.Item,
        name: 'Zoom in',
        shortcut: shortcutsMap[ShortcutId.PdfZoomIn],
        onSelect: zoomDocumentIn,
      },
      {
        type: DropdownOptionType.Item,
        name: 'Zoom out',
        shortcut: shortcutsMap[ShortcutId.PdfZoomOut],
        onSelect: zoomDocumentOut,
      },
      {
        type: DropdownOptionType.Item,
        name: 'Zoom to 100%',
        shortcut: shortcutsMap[ShortcutId.PdfZoomReset],
        onSelect: resetZoom,
      },
    ],
    [shortcutsMap, zoomDocumentIn, zoomDocumentOut, resetZoom, displayValue, onChange],
  );

  return (
    <Dropdown
      trigger={
        <DropdownMenu.Trigger asChild>
          <Button className={styles.triggerElement}>
            {displayValue}%
            <ChevronDownIcon />
          </Button>
        </DropdownMenu.Trigger>
      }
      options={options}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      appendToDocumentBody
      contentClassName={styles.dropdownContent}
      alignOffset={-10}
    />
  );
}
