import React, { useCallback, useEffect, useMemo, useState } from 'react';

import getNextElementWithinContainer from '../../../shared/foreground/utils/getNextNodeWithinContainer';
import {
  createHeadingIdFromIndex,
  HEADING_TAG_SELECTOR,
  HEADING_TAGS,
  parseHeadingLevel,
} from '../../../shared/utils/tableOfContents';
import { useIsLeftSidebarHidden } from '../hooks/hooks';
import styles from './TableOfContents.module.css';

// Takes an element and generates a table of contents. It will update the content if needed
const defaultExport = React.memo(function TableOfContents({
  contentRoot,
  currentFocusedElement = null,
  createHeadingId,
}: {
  contentRoot: HTMLElement;
  currentFocusedElement: HTMLElement | null;
  createHeadingId?: (index: number) => string;
}): JSX.Element | null {
  const [activeId, setActiveId] = useState('');
  const leftSidebarHidden = useIsLeftSidebarHidden();

  // if we find any element with `data-rw-toc-level` it's a signal we should use custom logic
  const useCustomAttributes = useMemo(
    () => Boolean(contentRoot.querySelectorAll('[data-rw-toc-level]').length),
    // we need to pass `contentRoot.innerHTML` so it will reload on content change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentRoot, contentRoot.innerHTML],
  );

  const headings = useMemo((): HTMLElement[] => {
    if (useCustomAttributes) {
      return Array.from(contentRoot.querySelectorAll('[data-rw-toc-level]'));
    } else {
      return Array.from(contentRoot.querySelectorAll(HEADING_TAG_SELECTOR));
    }
    // we need to pass `contentRoot.innerHTML` so it will reload on content change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCustomAttributes, contentRoot, contentRoot.innerHTML]);

  const isHeading = useCallback(
    (el: Element): boolean => {
      if (useCustomAttributes) {
        return el.hasAttribute('data-rw-toc-level');
      } else {
        return HEADING_TAGS.includes(el.tagName.toLowerCase());
      }
    },
    [useCustomAttributes],
  );

  useEffect(() => {
    if (!currentFocusedElement || !contentRoot || !headings.length) {
      return;
    }

    if (isHeading(currentFocusedElement)) {
      setActiveId(currentFocusedElement.id);
      return;
    }

    const closestUpperHeading = getNextElementWithinContainer({
      container: contentRoot,
      direction: 'previous',
      element: currentFocusedElement,
      matcher: isHeading,
      checkParents: true,
    });

    const newClosestUpperHeadingId = closestUpperHeading?.id ?? headings[0].id;

    setActiveId(newClosestUpperHeadingId);
    // we need to pass `contentRoot.innerHTML` so it will reload on content change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRoot, currentFocusedElement, contentRoot.innerHTML, activeId, headings]);

  if (!headings.length) {
    return null;
  }

  let highestLevelHeading = Infinity;

  // Give each heading an ID and track the highest level heading for indentation later
  headings.forEach((heading: HTMLElement, index: number): void => {
    heading.id = (createHeadingId ?? createHeadingIdFromIndex)(index);

    const level = parseHeadingLevel(heading, useCustomAttributes);
    // h1 is higher than h2
    if (level < highestLevelHeading) {
      highestLevelHeading = level;
    }
  });

  const parsedCurrentUrl = new URL(window.location.href);
  parsedCurrentUrl.hash = '';
  const currentUrl = parsedCurrentUrl.toString();

  const items = headings.map((heading: HTMLElement): JSX.Element => {
    const itemId = heading.id;
    const isActive = activeId === itemId;
    const href = `${currentUrl}#${heading.id}`;

    const headingLevel = parseHeadingLevel(heading, useCustomAttributes) - highestLevelHeading + 1;
    const className = `${styles.listItemWrapper} ${isActive ? styles.active : ''} ${
      styles[`listItemLevel${headingLevel}`]
    }`;
    const listItemClassName = leftSidebarHidden ? styles.hidden : '';

    const headingDisplay = heading.dataset?.rwTocTitle ?? heading.innerText;
    // Link to the ID using the document fragment identifier
    return (
      <a href={href} className={className} key={itemId}>
        <div className={styles.bar} />
        <li className={listItemClassName} title={headingDisplay}>
          {headingDisplay}
        </li>
      </a>
    );
  });

  return (
    <div className={`${styles.root}`}>
      <h4 id="toc-title" className={`${styles.title} ${leftSidebarHidden ? styles.hidden : ''}`}>
        Contents
      </h4>
      <ol>{items}</ol>
    </div>
  );
});

export default defaultExport;
