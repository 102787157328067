import React from 'react';

import Icon from './Icon';

export default function MinusIcon({
  className = '',
  text = 'Minus',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.75 10C5.75 9.58579 6.08579 9.25 6.5 9.25L13.5 9.25C13.9142 9.25 14.25 9.58579 14.25 10C14.25 10.4142 13.9142 10.75 13.5 10.75L6.5 10.75C6.08579 10.75 5.75 10.4142 5.75 10Z" />
      </svg>
    </Icon>
  );
}
