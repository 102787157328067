import binarySearch from '../../utils/binarySearch';
import doRectsIntersect from './doRectsIntersect';

async function getFirstElementIntersectingRect({
  element,
  rect,
  scrollableRoot,
}: {
  element: HTMLElement;
  rect: DOMRect;
  scrollableRoot: HTMLElement;
}): Promise<HTMLElement | null> {
  if (!element) {
    return null;
  }

  const children = Array.from(element.children) as HTMLElement[];
  const firstChild = await binarySearch(children, (child) =>
    doRectsIntersect(rect, child.getBoundingClientRect()),
  );

  if (!firstChild) {
    return null;
  }

  if (!firstChild.children.length) {
    return firstChild;
  }

  return (
    (await getFirstElementIntersectingRect({ element: firstChild, rect, scrollableRoot })) ?? firstChild
  );
}

export default getFirstElementIntersectingRect;
