import { open as tauriShellOpen } from '@tauri-apps/plugin-shell';

import { browserOrDesktop } from '../../../shared/utils/browserOrDesktop';

/**
 * Cross-platform function for opening a URL. On desktop, opens in new tab in OS default browser, regardless of target argument.
 */
export const openURL = browserOrDesktop<typeof window.open>(
  // eslint-disable-next-line no-restricted-syntax
  window.open,
  openURLOnDesktop,
);

function openURLOnDesktop(
  url: string | URL | undefined,
  /* ignored on desktop */ _target: string | undefined,
  /* ignored on desktop */ _features: string | undefined,
): WindowProxy | null {
  if (url) {
    tauriShellOpen(url.toString());
  }
  return null;
}
