import React, { useEffect, useState } from 'react';

import forwardRef from '../../../shared/foreground/utils/forwardRef';
import JustSanitizedDocumentContentOnly, {
  Props as JustSanitizedDocumentContentOnlyProps,
} from './JustSanitizedDocumentContentOnly';

type Props = JustSanitizedDocumentContentOnlyProps;

const defaultExport = React.memo(
  forwardRef<Props, HTMLDivElement>(function SanitizedDocumentContent(
    { content: contentArgument, ...props },
    ref,
  ): JSX.Element {
    const [content, setContent] = useState(contentArgument);
    useEffect(() => setContent(contentArgument), [contentArgument, setContent]);

    return <JustSanitizedDocumentContentOnly {...props} content={content} ref={ref} />;
  }),
);

// defaultExport.whyDidYouRender = {
//   trackHooks: true,
//   logOnDifferentValues: true,
// };

export default defaultExport;
