import React from 'react';

import { messageCopy } from '../../../shared/foreground/messageCopy';
import { Dialog } from './Dialog';

export const DeleteFeedDialog = ({
  isOpen,
  onConfirm,
  onCancel,
  message = messageCopy.removeFeedWarningText,
}: { isOpen: boolean; onConfirm: () => void; onCancel: () => void; message?: string }) => {
  if (isOpen) {
    return (
      <div>
        <Dialog
          id="delete-feed"
          title="Remove Subscription?"
          subtitle={message}
          actionTitle="Remove"
          cancelTitle="Cancel"
          redActionButton
          action={onConfirm}
          cancelAction={onCancel}
        />
      </div>
    );
  }
  return <></>;
};
