import { FileDocument } from '../types';
import getServerBaseUrl from '../utils/getServerBaseUrl.platform';
import requestWithAuth from '../utils/requestWithAuth.platformIncludingExtension';
import background from './portalGates/toBackground/singleProcess';

export const getFilePath = (docId?: FileDocument['parsed_doc_id']) => {
  return `file/${docId}`;
};

export const loadDocument = async (docId?: FileDocument['parsed_doc_id']): Promise<Blob> => {
  // Check if the document already exists in storage
  const item: Blob | null = await background.getCacheItem(getFilePath(docId));
  if (item !== null) {
    return item;
  }
  const response = await requestWithAuth(`${getServerBaseUrl()}/reader/document_raw_content/${docId}`, {
    credentials: 'include',
    method: 'GET',
    mode: 'cors',
  });
  const blob = await response.blob();
  await background.setCacheItem(getFilePath(docId), blob);
  return blob;
};
