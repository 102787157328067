import React from 'react';

import Icon from './Icon';

export default function AppearanceIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="AppearanceIcon">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.69637 4.72146C6.58248 4.43671 6.30669 4.25 6.00002 4.25C5.69334 4.25 5.41756 4.43671 5.30366 4.72146L1.30366 14.7215C1.14982 15.106 1.33689 15.5425 1.72147 15.6964C2.10606 15.8502 2.54254 15.6631 2.69637 15.2785L3.90779 12.25H8.09224L9.30366 15.2785C9.45749 15.6631 9.89397 15.8502 10.2786 15.6964C10.6631 15.5425 10.8502 15.106 10.6964 14.7215L6.69637 4.72146ZM6.00002 7.01944L7.49224 10.75H4.50779L6.00002 7.01944Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0694 8.30059C13.8107 8.60248 13.75 8.92945 13.75 9C13.75 9.41421 13.4142 9.75 13 9.75C12.5858 9.75 12.25 9.41421 12.25 9C12.25 8.57055 12.4393 7.89752 12.9306 7.32441C13.4525 6.71548 14.288 6.25 15.5 6.25C16.712 6.25 17.5475 6.71548 18.0694 7.32441C18.5607 7.89752 18.75 8.57055 18.75 9V15C18.75 15.4142 18.4142 15.75 18 15.75C17.6639 15.75 17.3794 15.5289 17.2841 15.2242C16.7845 15.5297 16.1789 15.7355 15.5162 15.7498C14.6503 15.7685 13.8386 15.5688 13.2259 15.0903C12.5918 14.595 12.25 13.8612 12.25 13C12.25 12.5181 12.3514 12.0877 12.5643 11.7166C12.7772 11.3456 13.0748 11.0805 13.3954 10.8881C13.9773 10.539 14.7126 10.3923 15.2915 10.2768L15.3529 10.2646C16.0153 10.1321 16.4981 10.0265 16.8329 9.82563C16.981 9.73674 17.0741 9.64247 17.1346 9.5369C17.1951 9.43143 17.25 9.26812 17.25 9C17.25 8.92945 17.1893 8.60248 16.9306 8.30059C16.7025 8.03452 16.288 7.75 15.5 7.75C14.712 7.75 14.2975 8.03452 14.0694 8.30059ZM15.7085 11.7232C16.1748 11.6302 16.7426 11.5169 17.25 11.2939V13C17.25 13.1271 17.1402 13.4303 16.7815 13.7404C16.4455 14.0309 15.984 14.2394 15.4838 14.2502C14.8497 14.2639 14.4114 14.1129 14.1491 13.9081C13.9082 13.7199 13.75 13.4376 13.75 13C13.75 12.7319 13.8049 12.5686 13.8654 12.4631C13.9259 12.3575 14.019 12.2633 14.1671 12.1744C14.5019 11.9735 14.9847 11.8679 15.6471 11.7354L15.7085 11.7232Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
}
