import React from 'react';

import Icon from './Icon';

export default function SearchNavIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="SearchNavIcon">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.5 2.25C5.04822 2.25 2.25 5.04822 2.25 8.5C2.25 11.9518 5.04822 14.75 8.5 14.75C9.95561 14.75 11.295 14.2524 12.3573 13.418L16.4697 17.5303C16.7626 17.8232 17.2374 17.8232 17.5303 17.5303C17.8232 17.2374 17.8232 16.7626 17.5303 16.4697L13.418 12.3573C14.2524 11.295 14.75 9.95561 14.75 8.5C14.75 5.04822 11.9518 2.25 8.5 2.25ZM3.75 8.5C3.75 5.87665 5.87665 3.75 8.5 3.75C11.1234 3.75 13.25 5.87665 13.25 8.5C13.25 11.1234 11.1234 13.25 8.5 13.25C5.87665 13.25 3.75 11.1234 3.75 8.5Z" />
      </svg>
    </Icon>
  );
}
