import { RssFolder } from '../../types';

export function getFoldersByFeedId(rssFolders: RssFolder[]) {
  const initial: { [feedId: string]: RssFolder[] } = {};

  return rssFolders.reduce((acc, folder) => {
    const rssSourceIdsInFolder = folder.childrenRssItems.map((rssItem) => rssItem.rssSourceId);
    for (const rssSourceId of rssSourceIdsInFolder) {
      if (acc[rssSourceId]) {
        acc[rssSourceId].push(folder);
      } else {
        acc[rssSourceId] = [folder];
      }
    }

    return acc;
  }, initial);
}
