import { Category } from '../../types';
import { rwSanitizeDefault } from './default';
import { rwSanitizeForEPUBs } from './forEPUBs';
import { rwSanitizeForOriginalEmails } from './forOriginalEmails';

export function rwSanitizeHtml(content: string, category: Category, isOriginalEmailView?: boolean) {
  if (isOriginalEmailView) {
    return rwSanitizeForOriginalEmails(content, category);
  }
  if (category === Category.EPUB) {
    return rwSanitizeForEPUBs(content, category);
  }
  return rwSanitizeDefault(content, category);
}
