import { useEffect } from 'react';

export default (element: HTMLElement | null, callback?: (scrollTop: number) => void): void => {
  // Add or remove the drop shadow on header if document list is scrolled
  useEffect(() => {
    if (!element) {
      return;
    }
    const onScroll = () => {
      if (element.scrollTop > 0) {
        element.classList.add('top-drop-shadow');
      } else {
        element.classList.remove('top-drop-shadow');
      }
      if (callback) {
        callback(element.scrollTop);
      }
    };
    element.addEventListener('scroll', onScroll, { passive: true });

    return () => element.removeEventListener('scroll', onScroll);
  }, [callback, element]);
};
