import React from 'react';

import styles from './MainContentHeader.module.css';

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
  isPageScrolled?: boolean;
};

export default (function MainContentHeader({ children, className, isPageScrolled }) {
  const classes = [
    // this allows parents to overwrite styles with `:global(.mainContentHeader).theirClassName { ... }`
    'mainContentHeader',
    styles.mainContentHeader,
  ];
  if (isPageScrolled) {
    classes.push(styles.mainContentHeaderWhenPageIsScrolled);
  }
  if (className) {
    classes.push(className);
  }

  return <div className={classes.join(' ')}>{children}</div>;
} as React.FC<Props>);
