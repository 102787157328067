import { AnyDocument, Category, PartialDocument } from '../types';
import { isDocumentWithUrl, isYouTubeUrl } from '../typeValidators';

export default (
  currentDoc: AnyDocument | PartialDocument<AnyDocument, 'url' | 'category' | 'listening_time_seconds'>,
): number | undefined => {
  const isYouTube = isDocumentWithUrl(currentDoc) && currentDoc.url && isYouTubeUrl(currentDoc.url);

  return currentDoc.category === Category.Video || isYouTube
    ? currentDoc.listening_time_seconds
    : undefined;
};
