import React from 'react';

import Icon from './Icon';

export default function ArticlesIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="Articles">
      <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3 2.75C3 1.7835 3.74619 1 4.66667 1H8.48816C8.93018 1 9.35411 1.18437 9.66667 1.51256L12.5118 4.5C12.8244 4.82819 13 5.27331 13 5.73744V13.25C13 14.2165 12.2538 15 11.3333 15H4.66667C3.74619 15 3 14.2165 3 13.25V2.75ZM4.5 8C4.5 8.48325 5.03976 9 5.5 9H10.5C10.9602 9 11.5 8.60825 11.5 8.125C11.5 7.64175 10.9602 7 10.5 7H5.5C5.03976 7 4.5 7.51675 4.5 8ZM5.5 10C5.03976 10 4.5 10.5168 4.5 11C4.5 11.4832 5.03976 12 5.5 12H10.5C10.9602 12 11.5 11.4832 11.5 11C11.5 10.5168 10.9602 10 10.5 10H5.5Z" />
      </svg>
    </Icon>
  );
}
