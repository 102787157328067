import { FilteredView } from '../../types';

export default (filteredViews: { [p: string]: FilteredView }, rssKeys: string[]) => {
  return Object.keys(filteredViews).reduce(
    (acc, viewId) => {
      const feedIdsInQuery = rssKeys.filter((feedId) => {
        const possibleQueries = [
          `rssSource:"${feedId}"`,
          `rssSource:${feedId}`,
          `rss_source:"${feedId}"`,
          `rss_source:${feedId}`,
        ];

        const view = filteredViews[viewId];
        const query = view.query;
        return possibleQueries.some((possibleQuery) => query.includes(possibleQuery));
      });

      if (feedIdsInQuery.length) {
        for (const _feedId of feedIdsInQuery) {
          acc[_feedId] = [...(acc[_feedId] || []), filteredViews[viewId]];
        }
      }

      return acc;
    },
    {} as { [feedId: string]: FilteredView[] },
  );
};
