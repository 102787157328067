import { useEffect, useState } from 'react';

export default function FakeSuspense({
  children,
  delay,
  fallback,
}: { children: JSX.Element; delay: number; fallback: JSX.Element | null }) {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShown(true);
    }, delay);
  }, [delay]);

  return isShown ? children : fallback;
}
