import { useMemo } from 'react';

import type { FirstClassDocument } from '../../types';
import { TrackPlayerState } from '../../types/tts';
import { globalState } from '../models';

export function useIsTtsPlayerStateForThisDocument(
  documentId?: FirstClassDocument['id'],
): Exclude<TrackPlayerState, TrackPlayerState.Loading> {
  const tts = globalState((state) => state.tts);

  return useMemo(() => {
    if (tts?.playingDocId !== documentId) {
      return TrackPlayerState.Off;
    }
    return tts?.trackPlayerInfo?.isPlaying ? TrackPlayerState.Playing : TrackPlayerState.Paused;
  }, [documentId, tts?.playingDocId, tts?.trackPlayerInfo?.isPlaying]);
}
