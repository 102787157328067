import React from 'react';

import Icon from './Icon';

export default function CloseIcon({
  text = 'close',
  ...extraProps
}: {
  text?: string;
} & React.HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span {...extraProps}>
      <Icon text={text}>
        <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.9108 2.24414C13.2297 1.92521 13.2297 1.40812 12.9108 1.0892C12.5919 0.770268 12.0748 0.770268 11.7559 1.0892L7 5.84506L2.24414 1.0892C1.92521 0.770268 1.40812 0.770268 1.0892 1.0892C0.770269 1.40812 0.770269 1.92521 1.0892 2.24414L5.84506 7L1.0892 11.7559C0.770268 12.0748 0.770268 12.5919 1.0892 12.9108C1.40812 13.2297 1.92521 13.2297 2.24414 12.9108L7 8.15494L11.7559 12.9108C12.0748 13.2297 12.5919 13.2297 12.9108 12.9108C13.2297 12.5919 13.2297 12.0748 12.9108 11.7559L8.15494 7L12.9108 2.24414Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Icon>
    </span>
  );
}
