import { useCallback, useEffect, useState } from 'react';

import {
  FontSizeNumberDesktop,
  getNextTshirtSizeFromKeys,
  LineHeightNumberDesktop,
  LineLengthNumberDesktop,
  WEB_CSS_FONTS,
  WEB_DEFAULT_FONT,
} from '../../../shared/foreground/fonts';
import { globalState } from '../../../shared/foreground/models';
import {
  cycleTypeface,
  setFontSize,
  setLineSpacing,
  setReaderHorizontalMargin,
} from '../../../shared/foreground/stateUpdaters/clientStateUpdaters/readerSettings';
import { Font, TshirtSize } from '../../../shared/types';
import { ShortcutId } from '../../../shared/types/keyboardShortcuts';
import { useShortcutsMap } from '../utils/shortcuts';
import { useKeyboardShortcut } from './useKeyboardShortcut';

const FontSizesKeys = Object.keys(FontSizeNumberDesktop);
const LineHeightSizesKeys = Object.keys(LineHeightNumberDesktop);
const LineLengthKeys = Object.keys(LineLengthNumberDesktop);

export function useCurrentAppearanceStyle(): {
  fontSize: TshirtSize;
  lineLength: TshirtSize;
  font: Font;
  lineHeight: TshirtSize;
} {
  const fontSize = globalState(useCallback((state) => state.client.readerSettings.desktop.fontSize, []));
  const lineHeight = globalState(
    useCallback((state) => state.client.readerSettings.desktop.lineHeight, []),
  );
  const lineLength = globalState(
    useCallback((state) => state.client.readerSettings.desktop.lineLength, []),
  );
  const font = globalState(useCallback((state) => state.client.readerSettings.desktop.font, []));
  return { fontSize, lineHeight, lineLength, font };
}

export function useAppearanceStyles(): void {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const {
    fontSize: currentFontSize,
    lineHeight: currentLineHeight,
    lineLength: currentLineLength,
    font: currentFont,
  } = useCurrentAppearanceStyle();

  // Font family
  useEffect(() => {
    if (currentFont === WEB_DEFAULT_FONT || fontsLoaded) {
      return;
    }

    import('../../../shared/fonts/FontOptions.css');
    setFontsLoaded(true);
  }, [fontsLoaded, currentFont]);

  useEffect(() => {
    const root = document.querySelector<HTMLElement>(':root');
    if (!root) {
      return;
    }
    root.style.setProperty('--reading-editable-font-family', WEB_CSS_FONTS[currentFont]);
  }, [currentFont]);

  // Font size
  useEffect(() => {
    const root = document.querySelector<HTMLElement>(':root');
    if (!root) {
      return;
    }
    const numberFontSize = FontSizeNumberDesktop[currentFontSize];
    root.style.setProperty('--reading-editable-font-size', `${numberFontSize}`);
  }, [currentFontSize]);

  // Line height
  useEffect(() => {
    const root = document.querySelector<HTMLElement>(':root');
    if (!root) {
      return;
    }

    const numberLineHeight = LineHeightNumberDesktop[currentLineHeight];
    root.style.setProperty('--reading-editable-line-height', `${numberLineHeight}`);
  }, [currentLineHeight]);

  // Line length
  useEffect(() => {
    const root = document.querySelector<HTMLElement>(':root');

    if (!root) {
      return;
    }

    const numberLineLength = LineLengthNumberDesktop[currentLineLength];
    root.style.setProperty('--reading-editable-line-length', `${numberLineLength}px`);
  }, [currentLineLength]);
}

export function useAppearanceStyleHotkeys(isPDFView: boolean): void {
  const shortcutsMap = useShortcutsMap();
  const {
    fontSize: currentFontSize,
    lineHeight: currentLineHeight,
    lineLength: currentLineLength,
  } = useCurrentAppearanceStyle();

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.IncreaseFontSize],
    useCallback(() => {
      const nextSize = getNextTshirtSizeFromKeys({ keys: FontSizesKeys, currentSize: currentFontSize });
      setFontSize(nextSize, { userInteraction: 'keypress' });
    }, [currentFontSize]),
    {
      description: 'Increase font size',
      isEnabled: !isPDFView,
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.DecreaseFontSize],
    useCallback(() => {
      const nextSize = getNextTshirtSizeFromKeys({
        keys: FontSizesKeys,
        currentSize: currentFontSize,
        direction: -1,
      });
      setFontSize(nextSize, { userInteraction: 'keypress' });
    }, [currentFontSize]),
    {
      description: 'Decrease font size',
      isEnabled: !isPDFView,
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.WidenLineLength],
    useCallback(() => {
      const nextSize = getNextTshirtSizeFromKeys({
        keys: LineLengthKeys,
        currentSize: currentLineLength,
      });
      setReaderHorizontalMargin(nextSize, { userInteraction: 'keypress' });
    }, [currentLineLength]),
    {
      description: 'Widen line length',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.NarrowLineLength],
    useCallback(() => {
      const nextSize = getNextTshirtSizeFromKeys({
        keys: LineLengthKeys,
        currentSize: currentLineLength,
        direction: -1,
      });
      setReaderHorizontalMargin(nextSize, { userInteraction: 'keypress' });
    }, [currentLineLength]),
    {
      description: 'Narrow line length',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.IncreaseLineSpacing],
    useCallback(() => {
      const nextSize = getNextTshirtSizeFromKeys({
        keys: LineHeightSizesKeys,
        currentSize: currentLineHeight,
      });
      setLineSpacing(nextSize, { userInteraction: 'keypress' });
    }, [currentLineHeight]),
    {
      description: 'Increase line spacing',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.DecreaseLineSpacing],
    useCallback(() => {
      const nextSize = getNextTshirtSizeFromKeys({
        keys: LineHeightSizesKeys,
        currentSize: currentLineHeight,
        direction: -1,
      });
      setLineSpacing(nextSize, { userInteraction: 'keypress' });
    }, [currentLineHeight]),
    {
      description: 'Decrease line spacing',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.CycleTypeFace],
    useCallback(() => cycleTypeface({ userInteraction: 'keypress' }), []),
    { description: 'Cycle typeface' },
  );
}
