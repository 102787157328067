import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { globalState } from '../../../../shared/foreground/models';
import { setIsNotebookDropdownOpen } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/dropdowns';
import { AnyDocument } from '../../../../shared/types';
import { ShortcutId } from '../../../../shared/types/keyboardShortcuts';
import { notEmpty } from '../../../../shared/typeValidators';
import delay from '../../../../shared/utils/delay';
import urlJoin from '../../../../shared/utils/urlJoin';
import { AdaptiveHeaderContext } from '../../contexts';
import { useKeyboardShortcut, useKeyboardShortcutPreventDefault } from '../../hooks/useKeyboardShortcut';
import { useShortcutsMap } from '../../utils/shortcuts';
import { Dropdown, DropdownOptionType } from './Dropdown';

export default function DropdownNotebookHeader({
  parentDocId,
  triggerClassName,
}: { parentDocId?: AnyDocument['id']; triggerClassName: string }) {
  const { headerIsHidden, setHeaderIsHidden } = useContext(AdaptiveHeaderContext);
  const isNotebookDropdownOpen = globalState(useCallback((state) => state.isNotebookDropdownOpen, []));
  const shortcutsMap = useShortcutsMap();

  async function waitForMenuToAppear() {
    await delay(300);
  }

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.ToggleDocMoreActions],
    useCallback(async () => {
      if (isNotebookDropdownOpen) {
        setIsNotebookDropdownOpen(false);
        return;
      }

      if (headerIsHidden && setHeaderIsHidden) {
        setHeaderIsHidden(false);
        await waitForMenuToAppear();
      }

      setIsNotebookDropdownOpen(true);
    }, [headerIsHidden, isNotebookDropdownOpen, setHeaderIsHidden]),
    {
      description: 'Toggle notebook more actions dropdown',
    },
  );

  const history = useHistory();

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.ToggleNotebookView],
    useCallback(() => {
      history.push(urlJoin(['/read', parentDocId]));
      setIsNotebookDropdownOpen(false);
    }, [history, parentDocId]),
    { isEnabled: Boolean(parentDocId) },
  );

  const options = useMemo(() => {
    return [
      parentDocId
        ? {
            type: DropdownOptionType.Item,
            name: 'Open parent document',
            shortcut: shortcutsMap[ShortcutId.ToggleNotebookView],
            onSelect: () => {
              history.push(urlJoin(['/read', parentDocId]));
              setIsNotebookDropdownOpen(false);
              // TODO this should include the actual doc location (eg /feed/unseen/read...)
            },
          }
        : null,
    ].filter(notEmpty);
  }, [history, parentDocId, shortcutsMap]);

  return (
    <div>
      <Dropdown
        appendToDocumentBody
        triggerClassName={triggerClassName}
        triggerShortcut={shortcutsMap[ShortcutId.ToggleDocMoreActions]}
        triggerTooltipText="More actions"
        isOpen={isNotebookDropdownOpen}
        options={options}
        setIsOpen={setIsNotebookDropdownOpen}
      />
    </div>
  );
}
