import React from 'react';

import Icon from './Icon';

export default function ChevronRight({
  className,
  text = 'right',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        fill="none"
        height="12"
        width="8"
        viewBox="0 0 8 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1L6 6L1 11" stroke="#717273" strokeWidth="1.5" />
      </svg>
    </Icon>
  );
}
