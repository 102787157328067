import { isExtension, isMobile, isWebApp } from '../../../utils/environment';
import { globalState, updateState } from '../../models';
import { getIsAutoHighlightingEnabled } from '../../stateGetters';
import { createToast } from '../../toasts.platform';

export const setIsAutoHighlightingEnabled = async ({
  isEnabled,
  shouldShowToast,
  userInteraction,
}: {
  isEnabled: boolean;
  shouldShowToast?: boolean;
  userInteraction: string;
}): Promise<void> => {
  await updateState(
    (state) => {
      if (isExtension) {
        if (!state.persistent.settings.extension) {
          state.persistent.settings.extension = { isAutoHighlightingEnabled: isEnabled };
          return;
        }
        state.persistent.settings.extension.isAutoHighlightingEnabled = isEnabled;
        return;
      }
      if (isMobile) {
        if (!state.persistent.settings.mobile) {
          state.persistent.settings.mobile = { autoHighlight: isEnabled };
          return;
        }
        state.persistent.settings.mobile.autoHighlight = isEnabled;
        return;
      }
      if (isWebApp) {
        if (!state.persistent.settings.web) {
          state.persistent.settings.web = { isAutoHighlightingEnabled: isEnabled };
          return;
        }
        state.persistent.settings.web.isAutoHighlightingEnabled = isEnabled;
        return;
      }
      throw new Error('Unknown platform');
    },
    {
      eventName: 'auto-highlighting-setting-updated',
      userInteraction,
    },
  );

  if (shouldShowToast) {
    createToast({
      category: 'success',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      content: `Auto highlighting ${isEnabled ? 'enabled' : 'disabled'}`,
    });
  }
};

export const toggleIsAutoHighlightingEnabled = (userInteraction: string) =>
  setIsAutoHighlightingEnabled({
    isEnabled: !getIsAutoHighlightingEnabled(globalState.getState()),
    userInteraction,
  });
