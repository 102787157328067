import ISO6391 from 'iso-639-1';

export default (languageCodeParam: string | undefined): string => {
  let languageCode = languageCodeParam;
  if (!languageCode || languageCode === 'Unknown') {
    return 'Unknown';
  }
  // overrides for TTS supported languages
  if (languageCode === 'fil') {
    // manual override for filipino TG code
    languageCode = 'tl';
  } else if (languageCode === 'wuu') {
    return 'Chinese (Wu, Simplified)';
  } else if (languageCode === 'yue') {
    return 'Chinese (Cantonese, Simplified)';
  }
  return ISO6391.getName(languageCode);
};
