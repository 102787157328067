import React from 'react';

import Icon from './Icon';

export default function MutedSpeakerIcon({
  className,
  text = 'Volume (muted)',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.875 8.62498L9.854 5.25398C10.504 4.70298 11.5 5.16498 11.5 6.01698V17.982C11.5 18.834 10.503 19.296 9.854 18.745L5.875 15.374"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.875 15.375H3.5C2.948 15.375 2.5 14.927 2.5 14.375V9.625C2.5 9.073 2.948 8.625 3.5 8.625H5.875"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5801 9.68994L16.0801 14.1899"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5801 14.1899L16.0801 9.68994"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}
