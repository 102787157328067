import React from 'react';

import Icon from './Icon';

export default function TagIcon({
  className = '',
  text = 'Tag',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_467_34)">
          <path
            d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10ZM15.0338 7.25596L8.92265 13.3671C8.59721 13.6925 8.06957 13.6925 7.74413 13.3671L4.96635 10.5893C4.64092 10.2639 4.64092 9.73622 4.96635 9.41079C5.29179 9.08535 5.81943 9.08535 6.14487 9.41079L8.33339 11.5993L13.8552 6.07745C14.1807 5.75202 14.7083 5.75202 15.0338 6.07745C15.3592 6.40289 15.3592 6.93053 15.0338 7.25596Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_467_34">
            <rect width="20" height="20" fill="currentColor" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}
