import { type YouTubePlaybackRate } from '../types';

export const DefaultYouTubePlaybackRate: YouTubePlaybackRate = 1;
export const YouTubePlaybackRates: YouTubePlaybackRate[] = [
  0.25,
  0.5,
  0.75,
  DefaultYouTubePlaybackRate,
  1.25,
  1.5,
  1.75,
  2,
];
