import { useEffect } from 'react';

import { setScreenWidth } from '../stateUpdaters/transientStateUpdaters/other';

export default function useScreenWidthMonitor() {
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
}
