import React from 'react';

import Icon from './Icon';

export default function SortUpIcon({
  className = '',
  text = 'SortUp',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.4728 3.41779C10.1975 3.19419 9.80313 3.19406 9.52763 3.41746C8.22163 4.47652 7.5298 5.17111 6.42007 6.52444C6.15743 6.84474 6.20416 7.31731 6.52446 7.57995C6.84476 7.8426 7.31733 7.79586 7.57997 7.47556C8.21794 6.69756 8.69738 6.16268 9.25 5.64008V14.5332C9.25 14.9474 9.58579 15.2832 10 15.2832C10.4142 15.2832 10.75 14.9474 10.75 14.5332V5.64108C11.3027 6.164 11.7823 6.69865 12.4203 7.47587C12.6831 7.79603 13.1557 7.84251 13.4759 7.57969C13.7961 7.31687 13.8425 6.84428 13.5797 6.52413C12.4701 5.17245 11.7785 4.47809 10.4728 3.41779Z" />
      </svg>
    </Icon>
  );
}
