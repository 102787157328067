import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useMemo, useState } from 'react';

import { usePartialDocument } from '../../../../shared/foreground/stateHooks';
import { setPDFSidebarContentType } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/pdf';
import { toggleHideLeftSidebar } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/sidebars';
import { AnyDocument, SidebarContentType } from '../../../../shared/types';
import { ShortcutId } from '../../../../shared/types/keyboardShortcuts';
import { useIsLeftSidebarHidden } from '../../hooks/hooks';
import { useKeyboardShortcutPreventDefault } from '../../hooks/useKeyboardShortcut';
import { useShortcutsMap } from '../../utils/shortcuts';
import Button from '../Button';
import ChevronDownIcon from '../icons/ChevronDownSmallIcon';
import ToggleLeftPanelIcon from '../icons/ToggleLeftPanelIcon';
import Tooltip from '../Tooltip';
import { Dropdown, DropdownOptionType } from './Dropdown';
import styles from './SidebarContentTypeDropdown.module.css';

export default function SidebarContentTypeDropdown({
  docId,
  showChevron,
}: { docId?: AnyDocument['id']; showChevron?: boolean }) {
  const [isOpen, setIsOpen] = useState(false);
  const shortcutsMap = useShortcutsMap();
  const [doc] = usePartialDocument(docId, ['source_specific_data']);
  const currentSidebarContentType = useMemo(
    () => doc?.source_specific_data?.pdf?.sidebarContentType ?? SidebarContentType.Thumbnails,
    [doc],
  );
  const isThumbnailsContentType = currentSidebarContentType === SidebarContentType.Thumbnails;
  const leftSidebarHidden = useIsLeftSidebarHidden();

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.ChangeSidebarContentType],
    useCallback(() => {
      if (!docId) {
        return;
      }
      let newSidebarContentType: SidebarContentType;
      if (currentSidebarContentType === SidebarContentType.TableOfContent) {
        newSidebarContentType = SidebarContentType.Thumbnails;
      } else {
        newSidebarContentType = SidebarContentType.TableOfContent;
      }
      setPDFSidebarContentType(docId, newSidebarContentType, { userInteraction: 'keyup' });
    }, [docId, currentSidebarContentType]),
    {
      description: 'Toggle video settings panel',
    },
  );

  const options = useMemo(
    () => [
      {
        type: DropdownOptionType.Item,
        name: 'Hide sidebar',
        shortcut: shortcutsMap[ShortcutId.HideLeftPanel],
        onSelect: () => {
          toggleHideLeftSidebar({ userInteraction: 'click' });
        },
      },
      {
        type: DropdownOptionType.Item,
        name: 'Thumbnails',
        checked: isThumbnailsContentType,
        shortcut: shortcutsMap[ShortcutId.ChangeSidebarContentType],
        onSelect: () => {
          if (!docId) {
            return;
          }
          setPDFSidebarContentType(docId, SidebarContentType.Thumbnails, { userInteraction: 'keyup' });
        },
      },
      {
        type: DropdownOptionType.Item,
        name: 'Contents',
        checked: !isThumbnailsContentType,
        shortcut: shortcutsMap[ShortcutId.ChangeSidebarContentType],
        onSelect: () => {
          if (!docId) {
            return;
          }
          setPDFSidebarContentType(docId, SidebarContentType.TableOfContent, {
            userInteraction: 'keyup',
          });
        },
      },
    ],
    [docId, shortcutsMap, isThumbnailsContentType],
  );

  const _showChevron = !leftSidebarHidden && showChevron;

  return (
    <div className={`${styles.contentTypeButton} ${_showChevron ? styles.showChevron : ''}`}>
      <Tooltip
        content={`${leftSidebarHidden ? 'Show' : 'Hide'} left panel`}
        shortcut={shortcutsMap[ShortcutId.HideLeftPanel]}
      >
        <Button
          className={styles.toggleLeftPanelOpenButton}
          onClick={() => toggleHideLeftSidebar({ userInteraction: 'click' })}
        >
          <ToggleLeftPanelIcon />
        </Button>
      </Tooltip>

      {_showChevron && (
        <Dropdown
          trigger={
            <DropdownMenu.Trigger asChild>
              <Button className={styles.triggerElement}>
                <ChevronDownIcon />
              </Button>
            </DropdownMenu.Trigger>
          }
          options={options}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          appendToDocumentBody
          contentClassName={styles.dropdownContent}
          alignOffset={-10}
        />
      )}
    </div>
  );
}
