import React from 'react';

import Icon from '../icons/Icon';

export default function PlaceholderPdfsIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="PDFIcon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.73731 4.00691C3.27785 5.60611 6.5314 9.71992 10.8658 12.5102C12.2334 13.4036 18.8949 17.8152 19.8654 14.7822C20.6816 12.2235 17.7589 11.6279 11.8253 13.4808C4.40281 15.7969 4.10503 20.396 6.31082 20.8923C9.24452 21.565 9.807 14.9807 9.96141 13.7014C10.4467 9.04715 8.41735 2.29742 5.73731 4.00691Z"
          stroke="#A1AAC4"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}
