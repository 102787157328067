import { useEffect } from 'react';

import { type AnyDocument, type PartialDocument, Category } from '../types';
import { useAreEpubOriginalStylesEnabled } from './stateHooks';
import { setAreEpubOriginalStylesEnabled } from './stateUpdaters/persistentStateUpdaters/documents/epub';

export function useSetOriginalEpubStylesByDefault(
  doc: PartialDocument<AnyDocument, 'source_specific_data' | 'id' | 'category'> | null,
) {
  const areEpubOriginalStylesEnabled = useAreEpubOriginalStylesEnabled(doc);
  useEffect(() => {
    if (!doc || !doc.source_specific_data?.epub || doc.category !== Category.EPUB) {
      return;
    }
    if (areEpubOriginalStylesEnabled === undefined) {
      setAreEpubOriginalStylesEnabled(doc.id, true, { userInteraction: null });
    }
  }, [doc, areEpubOriginalStylesEnabled]);
}
