import React from 'react';

import Icon from './Icon';

export default function MinusIcon({
  className = '',
  text = 'Minus',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="20-stroke-plus">
          <path id="Vector 88" d="M8 5L8 11" stroke="#606C79" stroke-width="1.5" strokeLinecap="round" />
          <path id="Vector 89" d="M11 8H5" stroke="#606C79" stroke-width="1.5" strokeLinecap="round" />
        </g>
      </svg>
    </Icon>
  );
}
