import ttsController from '../../../shared/foreground/actions/ttsController.platform';
import contentFrame from '../../../shared/foreground/portalGates/contentFrame/to/reactNativeWebview';
import type { FirstClassDocument } from '../../../shared/types';

export default async function playOrStopTtsFromCurrentScrollPosition({
  currentlyOpenDocId,
}: {
  currentlyOpenDocId: FirstClassDocument['id'];
}) {
  const positionResult = await contentFrame.getPositionForTtsFromCurrentScrollPositionUsingIds(
    'document-text-content',
    'document-reader-root',
  );
  if (!positionResult) {
    return;
  }

  let startingPosition = positionResult.position;
  if (typeof positionResult.elementIndex !== 'undefined') {
    const positionForElement = await ttsController.fetchTimestampForElement(
      currentlyOpenDocId,
      positionResult.elementIndex,
    );
    if (positionForElement && positionForElement > 0) {
      startingPosition = positionForElement;
    }
  }

  ttsController.playOrStopDocument({
    docId: currentlyOpenDocId,
    startingPosition,
  });
}
