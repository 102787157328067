import React from 'react';

import styles from './Overlay.module.css';

export default function Overlay({
  className = '',
  ...props
}: { className?: string } & React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  return <div className={`${styles.overlay} ${className}`} {...props} />;
}
