import escapeStringRegexp from 'escape-string-regexp';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import * as hotkeys from 'react-hotkeys-hook';
import type ReactHotkeysHook from 'react-hotkeys-hook/dist/types';

import {
  setKeyboardShortcut,
  unsetKeyboardShortcut,
} from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/keyboardShortcuts';
import isComposing from '../../../shared/foreground/utils/isComposing';
import isElementTypable from '../../../shared/foreground/utils/isElementTypable';
import type { KeyboardApiName, KeyboardShortcut } from '../../../shared/types/keyboardShortcuts';
import type { LenientWindow } from '../../../shared/types/LenientWindow';
import { isMediaSessionAction } from '../../../shared/typeValidators';
import createKeyboardShortcutEvent from '../utils/createKeyboardShortcutEvent';
import aliasesWeReplace from '../utils/shortcuts/aliasesWeReplace';

declare let window: LenientWindow;

type CommonOptions = Pick<KeyboardShortcut, 'callback'> & {
  isEnabled: boolean;
  keys: string[] | [MediaSessionAction];
  preferredEventTrigger: 'keyup' | 'keydown'; // "preferred" because it might be neither if the MediaSession API is used
  shouldBeIgnoredInContentEditable: boolean;
  shouldBeIgnoredInFormTags: boolean;
};

function useMediaSession({ callback, isEnabled: isEnabledArgument, keys }: CommonOptions) {
  const isEnabled = useMemo(
    () => isEnabledArgument && 'mediaSession' in navigator && keys.length,
    [isEnabledArgument, keys],
  );

  useEffect(() => {
    if (!isEnabled) {
      return;
    }
    if (!isMediaSessionAction(keys[0])) {
      throw new Error('key is not a MediaSessionAction');
    }

    const mediaSessionAction = keys[0];
    navigator.mediaSession.setActionHandler(mediaSessionAction, function mediaSessionActionHandler() {
      callback(createKeyboardShortcutEvent('mediaSession'));
    });
    return () => {
      navigator.mediaSession.setActionHandler(mediaSessionAction, null);
    };
  }, [callback, isEnabled, keys]);
}

function useReactHotkeys<T extends HTMLElement>({
  callback,
  isEnabled,
  keys,
  preferredEventTrigger,
  shouldBeIgnoredInContentEditable,
  shouldBeIgnoredInFormTags,
}: CommonOptions) {
  const hotKeyOptions: hotkeys.Options = useMemo(
    () => ({
      enabled: isEnabled,
      enableOnContentEditable: !shouldBeIgnoredInContentEditable,
      enableOnFormTags: !shouldBeIgnoredInFormTags,
      keydown: preferredEventTrigger === 'keydown',
      keyup: preferredEventTrigger === 'keyup',
    }),
    [isEnabled, preferredEventTrigger, shouldBeIgnoredInContentEditable, shouldBeIgnoredInFormTags],
  );

  const hotKeysArguments: Parameters<typeof hotkeys.useHotkeys> = useMemo(() => {
    const keyEntries: string[] = [];
    let didDuplicateAKeyEntry = false;

    /*
      If no keys are provided, use a dummy key to prevent hotkeys from breaking
      but we are setting enabled: false in the options
    */
    const keysToUse = keys || ['-'];

    for (const item of keysToUse) {
      keyEntries.push(item);

      /*
        For cases like https://github.com/JohannesKlauss/react-hotkeys-hook/issues/947, we create multiple listeners
        with slight variations on the keys (e.g. ` -> ` and INTLBACKSLASH).
        Also see aliasesWeReplace.ts
      */
      for (const [typicalName, badValues] of Object.entries(aliasesWeReplace)) {
        for (const badValue of badValues) {
          const itemLowered = item.toLowerCase();
          const badValueLowered = badValue.toLowerCase();

          if (itemLowered.includes(typicalName)) {
            keyEntries.push(item.replace(new RegExp(escapeStringRegexp(typicalName), 'gi'), badValue));
            didDuplicateAKeyEntry = true;
          } else if (itemLowered.includes(badValueLowered)) {
            keyEntries.push(item.replace(new RegExp(escapeStringRegexp(badValue), 'gi'), typicalName));
            didDuplicateAKeyEntry = true;
          }
        }
      }
    }

    function reactHotKeysCallback(event: KeyboardEvent) {
      callback(createKeyboardShortcutEvent('react-hotkeys', event));
    }

    const debouncedWrappedCallback = didDuplicateAKeyEntry
      ? debounce(reactHotKeysCallback)
      : reactHotKeysCallback;

    // eslint-disable-next-line @shopify/react-hooks-strict-return
    return [keyEntries, debouncedWrappedCallback, hotKeyOptions];
  }, [callback, hotKeyOptions, keys]);

  return hotkeys.useHotkeys<T>(...hotKeysArguments);
}

function useSetKeyboardShortcut({
  customId,
  description,
  keys,
  shouldShowInHelp,
  callback,
}: Pick<CommonOptions, 'callback'> & {
  customId?: string;
  description: string;
  keys: string[];
  shouldShowInHelp: boolean;
}) {
  const currentShortcutKeysRef = useRef<string | null>(null);
  useEffect(() => {
    currentShortcutKeysRef.current = keys[0];
    const setKeyboardShortcutPromise = setKeyboardShortcut(
      {
        callback,
        customId,
        description,
        keys: keys[0],
        shouldShowInHelp,
      },
      { userInteraction: null },
    );

    return () => {
      currentShortcutKeysRef.current = null;
      (async () => {
        const id = await setKeyboardShortcutPromise;

        /*
          The same shortcut is being re-set. Skip the `unset...` call to avoid a race condition.
          `set...` being called again (without an `unset...` call in between) is safe.
        */
        if (currentShortcutKeysRef.current === keys[0]) {
          return;
        }
        unsetKeyboardShortcut(id, { userInteraction: null });
      })();
    };
  }, [customId, description, keys, shouldShowInHelp, callback]);
}

/*
  Use `CmdOrCtrl` in the keys will result in Command being used for Mac, Control being used for everything else.
  The ref returned is only used for the react-hotkeys-hook API. It's not a problem because you can't assign media keys
  when customizing a shortcut.
*/
export function useKeyboardShortcut<T extends HTMLElement>(
  keys: CommonOptions['keys'],
  callback: CommonOptions['callback'],
  options: Partial<CommonOptions> & {
    customId?: string;
    description?: string;
    shouldShowInHelp?: boolean;
  } = {},
): React.MutableRefObject<ReactHotkeysHook.RefType<T>> {
  const {
    customId,
    description = '',
    isEnabled: isEnabledArgument = true,
    preferredEventTrigger = 'keydown',
    shouldBeIgnoredInContentEditable = true,
    shouldBeIgnoredInFormTags = true,
    shouldShowInHelp = true,
  } = options;

  const isEnabled = useMemo(
    () => isEnabledArgument && keys.length > 0,
    [keys.length, isEnabledArgument],
  );

  const wrappedCallback: CommonOptions['callback'] = useCallback(
    (data) => {
      if (
        window.isRadixDropdownOpen ||
        window.isRecordingCustomShortcut ||
        (data.nativeKeyboardEvent && isComposing(data.nativeKeyboardEvent)) ||
        (shouldBeIgnoredInContentEditable && shouldBeIgnoredInFormTags && isElementTypable(data.target))
      ) {
        return;
      }

      callback(data);
    },
    [callback, shouldBeIgnoredInContentEditable, shouldBeIgnoredInFormTags],
  );

  useSetKeyboardShortcut({
    callback,
    customId,
    description,
    keys,
    shouldShowInHelp,
  });

  const getOptionsForApi = useCallback(
    (apiName: KeyboardApiName): CommonOptions => {
      const apiToUse =
        keys.length === 1 && isMediaSessionAction(keys[0]) ? 'mediaSession' : 'react-hotkeys';
      const isThisApiEnabled = isEnabled && apiName === apiToUse;
      return {
        callback: wrappedCallback,
        isEnabled: isThisApiEnabled,
        keys,
        preferredEventTrigger,
        shouldBeIgnoredInContentEditable,
        shouldBeIgnoredInFormTags,
      };
    },
    [
      isEnabled,
      keys,
      preferredEventTrigger,
      shouldBeIgnoredInContentEditable,
      shouldBeIgnoredInFormTags,
      wrappedCallback,
    ],
  );

  // One of the following at most is enabled at a time, depending on what kind of `keys` were given
  useMediaSession(getOptionsForApi('mediaSession'));
  return useReactHotkeys<T>(getOptionsForApi('react-hotkeys'));
}

export function useKeyboardShortcutPreventDefault<T extends HTMLElement>(
  keys: Parameters<typeof useKeyboardShortcut>[0],
  callback: Parameters<typeof useKeyboardShortcut>[1],
  options: Parameters<typeof useKeyboardShortcut>[2] = {},
) {
  const newCallback: KeyboardShortcut['callback'] = useCallback(
    (event) => {
      event.preventDefault();
      callback(event);
    },
    [callback],
  );
  return useKeyboardShortcut<T>(keys, newCallback, options);
}
