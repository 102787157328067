import { useEffect } from 'react';

import getSafeWindowHeight from '../utils/getSafeWindowHeight';

export default function useScrollIntoViewIfNeeded(
  ref: React.RefObject<HTMLElement>,
  shouldScroll: boolean,
  paddingTop = 0,
): void {
  useEffect(() => {
    if (ref.current && shouldScroll) {
      scrollIntoViewIfNeeded(ref.current, paddingTop);
    }
  }, [ref, shouldScroll, paddingTop]);
}

export function scrollIntoViewIfNeeded(target: HTMLElement, paddingTop = 0): void {
  if (target.getBoundingClientRect().bottom > getSafeWindowHeight()) {
    target.scrollIntoView(false);
  }

  if (target.getBoundingClientRect().top - paddingTop < 0) {
    target.scrollIntoView();
  }
}
