export default (date: string | number): number => {
  if (typeof date === 'string') {
    let timeDate = new Date(`${date} 00:00:00`).getTime();

    // In Safari timeDate is going to be NaN, we need to
    // replace - with / to make sure it works :facepalm:
    if (!timeDate) {
      timeDate = new Date(`${date.replaceAll('-', '/')} 00:00:00`).getTime();
    }

    return timeDate;
  }

  return date;
};
