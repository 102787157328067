import React from 'react';

import Icon from './Icon';

export default function SnapshotIcon({
  className = '',
  text = 'SnapshotIcon',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.75 15C18.75 15.4142 18.4142 15.75 18 15.75H15.75V18C15.75 18.4142 15.4142 18.75 15 18.75C14.5858 18.75 14.25 18.4142 14.25 18V15.75H12C11.5858 15.75 11.25 15.4142 11.25 15C11.25 14.5858 11.5858 14.25 12 14.25H14.25V12C14.25 11.5858 14.5858 11.25 15 11.25C15.4142 11.25 15.75 11.5858 15.75 12V14.25H18C18.4142 14.25 18.75 14.5858 18.75 15Z"
          fill="black"
        />
        <path
          d="M16.25 8V4.55565C16.25 4.11092 15.8891 3.75 15.4444 3.75L4.55565 3.75C4.11092 3.75 3.75 4.11092 3.75 4.55565L3.75 15.4444C3.75 15.8891 4.11092 16.25 4.55565 16.25H8C8.41421 16.25 8.75 16.5858 8.75 17C8.75 17.4142 8.41421 17.75 8 17.75H4.55565C3.28249 17.75 2.25 16.7175 2.25 15.4444L2.25 4.55565C2.25 3.28249 3.28249 2.25 4.55565 2.25L15.4444 2.25C16.7175 2.25 17.75 3.28249 17.75 4.55565V8C17.75 8.41421 17.4142 8.75 17 8.75C16.5858 8.75 16.25 8.41421 16.25 8Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
}
