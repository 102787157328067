import React from 'react';

import Icon from './Icon';

export default function ChevronDown({
  className = '',
  text = 'down',
}: { text?: string; className?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 13.25C10.1989 13.25 10.3897 13.171 10.5303 13.0303L15.5303 8.03033C15.8232 7.73744 15.8232 7.26256 15.5303 6.96967C15.2374 6.67678 14.7626 6.67678 14.4697 6.96967L10 11.4393L5.53033 6.96967C5.23744 6.67678 4.76256 6.67678 4.46967 6.96967C4.17678 7.26256 4.17678 7.73744 4.46967 8.03033L9.46967 13.0303C9.61032 13.171 9.80109 13.25 10 13.25Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
