import { updateState } from '../../models';

export const closeDeleteDocumentDialog = async (
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.isDeleteDocumentDialogOpen = false;
    },
    { ...options, eventName: 'close-delete-document-dialog', isUndoable: false },
  );
};
