import { type DocumentWithUrl } from '../types';
import getReaderAppBaseUrl from '../utils/getReaderAppBaseUrl';
import urlJoin from '../utils/urlJoin';
import copyTextToClipboard from './utils/copyTextToClipboard';

export default (documentId: DocumentWithUrl['id']): void => {
  copyTextToClipboard(urlJoin([getReaderAppBaseUrl(), 'read', documentId]), {
    successToastMessage: 'Copied Reader URL to clipboard',
  });
};
