import { getDocument } from '../../../shared/foreground/stateGetters';
import { createToast } from '../../../shared/foreground/toasts.platform';
import { Category, DocumentWithUrl } from '../../../shared/types';
import { openURL } from './openURL';

export async function openOriginalDocument(documentId: DocumentWithUrl['id']): Promise<void> {
  const doc = await getDocument<DocumentWithUrl>(documentId);

  if (!doc) {
    return;
  }

  const isDistributable = Boolean(doc && !doc.non_distributable);
  if (!isDistributable) {
    createToast({ content: 'This action is not supported for this document', category: 'info' });
    return;
  }

  if (doc?.category === Category.Email) {
    createToast({ content: 'This action is not supported on emails', category: 'info' });
    return;
  }

  if (doc && doc.url) {
    openURL(doc.url, '_blank');
  }
}
