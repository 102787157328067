import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useMemo, useState } from 'react';
import { MangoQuery } from 'rxdb';

import { openEditViewNameSubMenu } from '../../../../shared/foreground/cmdPalette';
import {
  setFilteredViewIdToDelete,
  setFocusedViewId,
} from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import type { AnyDocument, FilteredView } from '../../../../shared/types';
import { ShortcutId } from '../../../../shared/types/keyboardShortcuts';
import { useShortcutsMap } from '../../utils/shortcuts';
import Ellipsis from '../icons/16StrokeEllipsis';
import styles from './BuiltInCategoryViewDropDown.module.css';
import { getSeparatorOption } from './docOptions';
import { Dropdown, DropdownOptionType } from './Dropdown';
import {
  getDeleteViewOption,
  getEnableBundleLinkOption,
  getMarkAllAsSeenOption,
  getOpenBulkActionsOption,
  getShowBadgeCountOption,
  getSplitViewOption,
} from './DropdownMainHeader';

type GetRssFolderDropdownOptionsProps = {
  view: FilteredView;
  documentIdsQuery?: MangoQuery<AnyDocument>;
  shortcutsMap: ReturnType<typeof useShortcutsMap>;
  isInSidebar?: boolean;
};

export const getRssFolderDropdownOptions = ({
  view,
  documentIdsQuery,
  shortcutsMap,
  isInSidebar = false,
}: GetRssFolderDropdownOptionsProps) => {
  return [
    {
      type: DropdownOptionType.Item,
      name: 'Rename folder',
      onSelect: () => {
        setFocusedViewId(view.id);
        openEditViewNameSubMenu();
      },
    },
    getSplitViewOption({
      isDisabled: isInSidebar,
      shortcut: shortcutsMap[ShortcutId.OpenSplitBySubMenu],
    }),
    getEnableBundleLinkOption({ view, documentIdsQuery }),
    getSeparatorOption(),
    getMarkAllAsSeenOption({ documentIdsQuery }),
    getOpenBulkActionsOption({
      isDisabled: isInSidebar,
      shortcut: shortcutsMap[ShortcutId.OpenBulkActionsSubMenu],
    }),
    getSeparatorOption(),
    getShowBadgeCountOption({ view }),
    getSeparatorOption(),
    getDeleteViewOption({
      name: 'Delete folder',
      onSelect: () => setFilteredViewIdToDelete(view.id),
    }),
  ];
};

const Trigger = () => {
  return (
    <span>
      <Ellipsis />
    </span>
  );
};

export default React.memo(function RssFolderDropDown({
  view,
  onOpenChange,
  getViewMangoQueryWithoutSort,
}: {
  view: FilteredView;
  onOpenChange: (isOpen: boolean) => void;
  getViewMangoQueryWithoutSort: () => MangoQuery<AnyDocument> | undefined;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const shortcutsMap = useShortcutsMap();

  const TriggerElement = (
    <DropdownMenu.Trigger className={`${styles.dropdownTrigger} ${isOpen ? styles.isOpen : ''}`}>
      <Trigger />
    </DropdownMenu.Trigger>
  );

  const options = useMemo(() => {
    return getRssFolderDropdownOptions({
      view,
      documentIdsQuery: getViewMangoQueryWithoutSort(),
      shortcutsMap,
      isInSidebar: true,
    });
  }, [view, getViewMangoQueryWithoutSort, shortcutsMap]);

  // Appending a lot of dropdowns to the body makes the transition to this view
  // a bit slow. So we are going to append it only after the user actually clicks it.

  if (isOpen) {
    return (
      <Dropdown
        trigger={TriggerElement}
        options={options}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onOpenChange={onOpenChange}
        contentClassName={styles.dropdownContent}
        appendToDocumentBody
      />
    );
  }

  return (
    <button
      type="button"
      className={styles.dropdownTrigger}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onOpenChange(true);
        setIsOpen(true);
      }}
    >
      <Trigger />
    </button>
  );
});
