import sanitizeHtml, { type Attributes } from 'sanitize-html';

import { Category } from '../../types';
import { isMobile } from '../environment';
import {
  allowVulnerableTags,
  getDefaultAllowedAttributes,
  getDefaultAllowedTags,
  getDefaultTransformTags,
} from './common';

const ONLY_DIGITS_REGEX = /^\d+$/;

function replaceInlineStylesWidthToFitWindow(attribs: Attributes, styleProperty: string) {
  if (!attribs.style?.includes(styleProperty)) {
    return;
  }
  const styleArray = attribs.style.split(';');
  // eslint-disable-next-line @shopify/prefer-early-return
  styleArray.forEach((attribute, index) => {
    if (attribute.includes(styleProperty) && !attribute.includes('%')) {
      const attributeValue = attribute.replace(`${styleProperty}:`, '').replace('!important', '').trim();
      if (attributeValue.includes('px')) {
        const pxValue = attributeValue.replace('px', '').trim();
        if (ONLY_DIGITS_REGEX.test(pxValue)) {
          const numericValue = parseFloat(pxValue);
          if (numericValue > window.innerWidth) {
            styleArray[index] = attribute.replace(attributeValue, `100%`);
          }
        }
      }
    }
  });
  attribs.style = styleArray.join(';');
}

function replaceWidthStylesToFitWindow(attribs: Attributes) {
  replaceInlineStylesWidthToFitWindow(attribs, 'max-width');
  replaceInlineStylesWidthToFitWindow(attribs, 'width');
  if (typeof attribs.width === 'string') {
    const numericValue = parseFloat(attribs.width);
    if (numericValue > window.innerWidth) {
      attribs.width = `${window.innerWidth}`;
    }
  }
}

function parseOriginalEmailContent(content: string): string | null | undefined {
  const htmlDoc = new DOMParser().parseFromString(content, 'text/html');
  const scriptElement = htmlDoc.querySelector('script.rw-email-original');
  if (!scriptElement) {
    return;
  }
  return scriptElement.textContent;
}

export function rwSanitizeForOriginalEmails(contentBeforeParse: string, category: Category) {
  // we keep inline styles for images in emails, as sometimes they're needed to properly size the images.
  // TODO: the parser could transform the inline widths/heights to width/height dom properties so we could avoid this
  const content = parseOriginalEmailContent(contentBeforeParse) || contentBeforeParse;
  const shouldAllowImageStyles = true;
  const allowedTags = getDefaultAllowedTags();
  const allowedAllAttrs = [
    'style',
    'align',
    'alt',
    'background',
    'bgcolor',
    'border',
    'colspan',
    'cellpadding',
    'cellspacing',
    'color',
    'dir',
    'face',
    'height',
    'rowspan',
    'valign',
    'width',
  ];
  const allowedClasses = { '*': ['*'] }; // eslint-disable-line
  const defaultTransformTags = getDefaultTransformTags(shouldAllowImageStyles, category);
  return sanitizeHtml(content, {
    allowedAttributes: getDefaultAllowedAttributes(allowedAllAttrs, shouldAllowImageStyles),
    allowedClasses,
    allowedTags,
    // For Style tag warning (style tag is considered dangerous)
    allowVulnerableTags,
    transformTags: {
      ...defaultTransformTags,
      img: (tagName: string, attribs: Attributes) => {
        attribs.referrerpolicy = 'no-referrer';
        attribs.class = 'allow-image-styles';
        if (isMobile) {
          // If max-width is set to bigger than window.width then we adjust it to fit window.width
          replaceWidthStylesToFitWindow(attribs);
        }
        return {
          attribs,
          tagName: 'img',
        };
      },
      ...(isMobile
        ? {
            div: (tagName: string, attribs: Attributes) => {
              // If max-width is set to bigger than window.width then we adjust it to fit window.width
              replaceWidthStylesToFitWindow(attribs);
              return {
                attribs,
                tagName: 'div',
              };
            },
          }
        : {}),
    },
  });
}
