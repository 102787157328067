import React from 'react';

import Icon from '../icons/Icon';

export default function EpubsIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="EpubsIcon">
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M22 6C22 6 20 4 17 4C14 4 12 6 12 6C12 6 10 4 7 4C4 4 2 6 2 6V17.5858C2 18.4767 3.19619 19.0478 4.00551 18.6754C4.81201 18.3042 5.82995 18 7 18C8.90554 18 10.4076 18.8069 11.25 19.3957C11.7338 19.7338 12 20 12 20C12 20 12.2662 19.7338 12.75 19.3957C13.5924 18.8069 15.0945 18 17 18C18.1701 18 19.188 18.3042 19.9945 18.6754C20.8038 19.0478 22 18.4767 22 17.5858V6ZM12.75 17.6306V7.37132L13.0554 7.06594L13.0577 7.06375C13.0639 7.05783 13.0771 7.04535 13.0972 7.02722C13.1375 6.99088 13.2045 6.93232 13.2964 6.8588C13.4811 6.71103 13.7606 6.50727 14.1192 6.30236C14.8426 5.88899 15.8357 5.5 17 5.5C18.1643 5.5 19.1574 5.88899 19.8808 6.30236C20.1258 6.44236 20.3338 6.58182 20.5 6.70325V17.2578C19.5615 16.842 18.3727 16.5 17 16.5C15.2341 16.5 13.7725 17.0654 12.75 17.6306ZM11.25 7.37132V17.6306C10.2275 17.0654 8.76585 16.5 7 16.5C5.62734 16.5 4.43845 16.842 3.5 17.2578V6.70325C3.66615 6.58182 3.87422 6.44236 4.11921 6.30236C4.84261 5.88899 5.83567 5.5 7 5.5C8.16433 5.5 9.15739 5.88899 9.88079 6.30236C10.2394 6.50727 10.5189 6.71103 10.7036 6.8588C10.7955 6.93232 10.8625 6.99088 10.9028 7.02722C10.9229 7.04535 10.9361 7.05783 10.9423 7.06375L10.9446 7.06594L11.25 7.37132Z" />
      </svg>
    </Icon>
  );
}
