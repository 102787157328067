import isEqual from 'lodash/isEqual';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MangoQueryNoLimit } from 'rxdb';

import { DEFAULT_MANGO_QUERY_LIMIT } from '../../../shared/constants.platform';
import { useFindInfinite } from '../../../shared/foreground/databaseHooks';
import { useSetFocusedDocumentListQueryInZustand } from '../../../shared/foreground/stateHooks/setters';
import { setFocusedDocumentId } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { AnyDocument } from '../../../shared/types';
import {
  DatabaseCollectionNamesToDocType,
  InfiniteDatabaseHookResultObject,
} from '../../../shared/types/database';

const pageSize = DEFAULT_MANGO_QUERY_LIMIT;

export default function useDocumentIdsForDocumentList<
  TDocType extends DatabaseCollectionNamesToDocType['documents'],
>({
  createMangoQuery,
  openDocumentId,
}: {
  createMangoQuery: () => MangoQueryNoLimit<TDocType> | undefined;
  openDocumentId?: AnyDocument['id'];
}): {
  documentIds: TDocType['id'][];
  hasInitialFocusIndicatorLocationBeenDetermined: boolean;
  pageSize: number;
  setHasInitialFocusIndicatorLocationBeenDetermined: Dispatch<SetStateAction<boolean>>;
  shouldShowSkeleton: boolean;
} & Pick<InfiniteDatabaseHookResultObject<string>, 'fetchMore' | 'totalCount'> {
  const [mangoQuery, setMangoQuery] = useState(createMangoQuery());

  useSetFocusedDocumentListQueryInZustand(mangoQuery);

  const [
    hasInitialFocusIndicatorLocationBeenDetermined,
    setHasInitialFocusIndicatorLocationBeenDetermined,
  ] = useState(false);
  const [resultIds, setResultIds] = useState<TDocType['id'][]>([]);

  const [
    newDocuments,
    {
      isFetchingInitialInput: isFetchingIds,
      isFetching,
      fetchMore,
      isFetchingUpdatedInput,
      reset,
      totalCount,
    },
  ] = useFindInfinite('documents', mangoQuery, { pageSize });

  const isFirstFetchComplete = useRef(false);
  const shouldShowSkeleton = useMemo(() => isFetching && !isFirstFetchComplete.current, [isFetching]);

  useEffect(() => {
    if (!isFetching && !isFirstFetchComplete.current) {
      isFirstFetchComplete.current = true;
    }
  }, [isFetching]);

  useEffect(() => {
    const newQuery = createMangoQuery();
    if (Boolean(newQuery) && !isEqual(mangoQuery, newQuery)) {
      setResultIds([]);
      setMangoQuery(newQuery);
      reset();
      isFirstFetchComplete.current = false;
      setHasInitialFocusIndicatorLocationBeenDetermined(false);
    }
  }, [mangoQuery, createMangoQuery, reset]);

  useEffect(() => {
    const newDocIds = newDocuments.map((doc) => doc.id);
    if (isFetchingIds || isEqual(resultIds, newDocIds)) {
      return;
    }
    setResultIds(newDocIds);
  }, [isFetching, isFetchingIds, isFetchingUpdatedInput, newDocuments, resultIds]);

  useEffect(() => {
    if (isFetchingIds || hasInitialFocusIndicatorLocationBeenDetermined) {
      return;
    }

    if (resultIds.length === 0 && !openDocumentId) {
      setFocusedDocumentId(null, { userInteraction: null });
      return;
    }

    setHasInitialFocusIndicatorLocationBeenDetermined(true);
    if (openDocumentId) {
      setFocusedDocumentId(openDocumentId, { userInteraction: null });
    } else {
      setFocusedDocumentId(resultIds[0], { userInteraction: null });
    }
  }, [
    hasInitialFocusIndicatorLocationBeenDetermined,
    isFetchingIds,
    openDocumentId,
    resultIds,
    setHasInitialFocusIndicatorLocationBeenDetermined,
  ]);

  return {
    documentIds: resultIds,
    fetchMore,
    hasInitialFocusIndicatorLocationBeenDetermined,
    pageSize,
    setHasInitialFocusIndicatorLocationBeenDetermined,
    shouldShowSkeleton,
    totalCount,
  };
}
