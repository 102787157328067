import React from 'react';

import Icon from './Icon';

export default function EmailsIconHeader({
  className = '',
  text = 'Emails',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.46855 6.41435C5.1451 6.15559 4.67313 6.20803 4.41438 6.53148C4.15562 6.85492 4.20806 7.32689 4.53151 7.58565L9.01093 11.1692C9.58919 11.6318 10.4109 11.6318 10.9891 11.1692L15.4685 7.58565C15.792 7.32689 15.8444 6.85492 15.5857 6.53148C15.3269 6.20803 14.855 6.15559 14.5315 6.41435L10.0521 9.99788C10.0217 10.0222 9.9784 10.0222 9.94797 9.99788L5.46855 6.41435Z" />
        <path d="M5 3.25C2.92893 3.25 1.25 4.92893 1.25 7V13C1.25 15.0711 2.92893 16.75 5 16.75H15C17.0711 16.75 18.75 15.0711 18.75 13V7C18.75 4.92893 17.0711 3.25 15 3.25H5ZM2.75 7C2.75 5.75736 3.75736 4.75 5 4.75H15C16.2426 4.75 17.25 5.75736 17.25 7V13C17.25 14.2426 16.2426 15.25 15 15.25H5C3.75736 15.25 2.75 14.2426 2.75 13V7Z" />
      </svg>
    </Icon>
  );
}
