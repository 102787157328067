import format from 'date-fns/format';
import isThisYear from 'date-fns/isThisYear';
import isToday from 'date-fns/isToday';
import isValid from 'date-fns/isValid';

import { fixDatestamp } from './datestamp';

export default function getFormattedDate(date: Date): string {
  if (!isValid(date)) {
    return '';
  }
  let template: string;

  if (isToday(date)) {
    template = 'h:mm aaa';
  } else {
    template = 'MMM do';
    if (!isThisYear(date)) {
      template += ' yyyy';
    }
  }
  return format(fixDatestamp(date), template);
}
