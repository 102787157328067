export default (element: HTMLElement, ancestor: HTMLElement): { left: number; top: number } => {
  if (!ancestor.contains(element)) {
    throw new Error('element is not a descendant of ancestor');
  }

  const result = { left: 0, top: 0 };

  for (
    let current = element;
    current && !current.isEqualNode(ancestor) && !current.contains(ancestor);
    current = current.offsetParent as HTMLElement
  ) {
    result.left += current.offsetLeft;
    result.top += current.offsetTop;
  }

  return result;
};
