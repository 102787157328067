import React from 'react';

import Icon from './Icon';

export default function KeyboardIcon({
  text = 'keyboard shortcut',
}: {
  text?: string;
}): JSX.Element {
  return (
    <Icon text={text}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M16 2.99999C17.6569 2.99999 19 4.34314 19 5.99999V14C19 15.6568 17.6569 17 16 17C12 17 8 17 4 17C2.34315 17 1 15.6568 1 14C1 11.3333 1 8.66664 1 5.99997C1 4.34313 2.34312 2.99999 3.99997 2.99998C7.99998 2.99996 12 2.99998 16 2.99999ZM11 5.99999H9V7.99999H11V5.99999ZM11 8.99999H9V11H11V8.99999ZM14 5.99999H12V7.99999L14 7.99999V5.99999ZM14 8.99999L12 8.99999V11H14V8.99999ZM15 11L17 11V8.99999H15V11ZM15 7.99999H17V5.99999L15 5.99999V7.99999ZM6 15H14V13H6L6 15ZM6 11H8V8.99999L6 8.99999V11ZM6 7.99999L8 7.99999V5.99999L6 5.99999V7.99999ZM3 11L5 11V8.99999H3L3 11ZM3 7.99999H5V5.99999L3 5.99999L3 7.99999Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
