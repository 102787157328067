/**
 * A timestamp is a datestamp if the time component is set to exactly zero.
 * Datestamps should be treated by the code as referencing a date but not an exact time.
 *
 * NOTE: In rare cases this could be wrong: a date could be correctly exactly at midnight UTC, and we would
 * interpret it as a datestamp. In the future we should make datestamps more robust.
 */
export function isDatestamp(date: Date): boolean {
  return (
    date.getUTCHours() === 0 &&
    date.getUTCMinutes() === 0 &&
    date.getUTCSeconds() === 0 &&
    date.getUTCMilliseconds() === 0
  );
}

export function fixDatestamp(date: Date): Date {
  if (!isDatestamp(date)) {
    return date;
  }
  // Fix datestamp to be in local timezone instead, otherwise it could be displayed as a day earlier or later.
  const timezoneOffsetMillis = new Date().getTimezoneOffset() * 60 * 1000;
  return new Date(date.getTime() + timezoneOffsetMillis);
}
