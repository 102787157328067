import React from 'react';

import Icon from './Icon';

export default function SolidTrashIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="Trash">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.25 2C7.25 1.58579 7.58579 1.25 8 1.25H12C12.4142 1.25 12.75 1.58579 12.75 2C12.75 2.41421 12.4142 2.75 12 2.75H8C7.58579 2.75 7.25 2.41421 7.25 2Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.75 5C1.75 4.58579 2.08579 4.25 2.5 4.25H17.5C17.9142 4.25 18.25 4.58579 18.25 5C18.25 5.41421 17.9142 5.75 17.5 5.75H15.75V14C15.75 16.0711 14.0711 17.75 12 17.75H8C5.92893 17.75 4.25 16.0711 4.25 14V5.75H2.5C2.08579 5.75 1.75 5.41421 1.75 5ZM9 9C9 8.44772 8.55228 8 8 8C7.44772 8 7 8.44772 7 9V14C7 14.5523 7.44772 15 8 15C8.55228 15 9 14.5523 9 14V9ZM13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V9Z"
        />
      </svg>
    </Icon>
  );
}
