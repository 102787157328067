import { isDevOrTest, isMobile } from '../../../utils/environment';
import makeLogger from '../../../utils/makeLogger';
import { createToast } from '../../toasts.platform';
import writeText from './_writeText.platform';

const logger = makeLogger(__filename);
const defaultSuccessToastMessage = isMobile ? 'Copied' : 'Copied to clipboard';

export default async function copyTextToClipboard(
  text: string,
  options?: {
    successToastMessage: string | null; // null disables it
  },
): Promise<void> {
  if (!text) {
    const message = 'No text given';
    logger.error(message);
    if (isDevOrTest) {
      throw new Error(message);
    }
    return;
  }

  const { successToastMessage = defaultSuccessToastMessage } = options ?? {};

  try {
    await writeText(text);
    if (successToastMessage) {
      createToast({ content: successToastMessage, category: 'success' });
    }
  } catch (error) {
    createToast({ content: 'Failed to copy to clipboard', category: 'error' });
    logger.error(error instanceof Error ? error.message : 'Copy errored', { error });
  }
}
