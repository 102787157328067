import { FirstClassDocument } from '../../types';
import { isExtension } from '../../utils/environment';
import { usePartialDocument } from '../stateHooks';

export default (docId: FirstClassDocument['id']): boolean => {
  if (isExtension) {
    return false;
  }

  const [doc] = usePartialDocument(docId, ['word_count']);
  if (typeof doc?.word_count !== 'number') {
    return false;
  }
  return doc.word_count >= 1000;
};
