type Side = 'bottom' | 'left' | 'right' | 'top';

// If the input is not a valid side, we assume it's `top`
export default function getOppositeSide(input: Side | string): Side {
  switch (input) {
    case 'top':
      return 'bottom';
    case 'bottom':
      return 'top';
    case 'left':
      return 'right';
    case 'right':
      return 'left';
    default:
      return 'bottom';
  }
}
