import isEqual from 'lodash/isEqual';

import { type KeyboardShortcut } from '../../../types/keyboardShortcuts';
import { CancelStateUpdate, globalState, updateState } from '../../models';
import { type StateUpdateOptionsWithoutEventName } from '../../types';

export const setKeyboardShortcut = async (
  shortcut: KeyboardShortcut,
  options: StateUpdateOptionsWithoutEventName,
): Promise<string> => {
  const id = shortcut.customId ?? `${shortcut.keys}__${shortcut.description}`;

  const existingShortcut = globalState.getState().keyboardShortcuts[id];
  if (isEqual(shortcut, existingShortcut)) {
    return id;
  }

  await updateState(
    (state) => {
      const existingShortcut = state.keyboardShortcuts[id];
      if (existingShortcut === shortcut || isEqual(existingShortcut, shortcut)) {
        throw new CancelStateUpdate();
      }
      state.keyboardShortcuts[id] = shortcut;
    },
    {
      ...options,
      shouldCreateUserEvent: false,
      eventName: 'set-keyboard-shortcut-description',
      isUndoable: false,
    },
  );
  return id;
};

export const unsetKeyboardShortcut = async (
  id: string,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      if (!state.keyboardShortcuts[id]) {
        throw new CancelStateUpdate();
      }
      delete state.keyboardShortcuts[id];
    },
    {
      ...options,
      shouldCreateUserEvent: false,
      eventName: 'unset-keyboard-shortcut-description',
      isUndoable: false,
    },
  );
};
