export default (rects) => {
  if (!rects.length) {
    throw new Error("No rects given");
  }

  const result = rects.slice(1).reduce((draftPatialResult, rect) => {
    const partialResult = {};

    ["bottom", "right"].forEach((key) => {
      partialResult[key] = Math.max(draftPatialResult[key], rect[key]);
    });

    ["left", "top", "x", "y"].forEach((key) => {
      partialResult[key] = Math.min(draftPatialResult[key], rect[key]);
    });

    return partialResult;
  }, rects[0]);

  result.height = Math.abs(result.bottom - result.top);
  result.width = Math.abs(result.right - result.left);

  return new DOMRect(result.x, result.y, result.width, result.height);
};
