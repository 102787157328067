import type { AnyDocument, PartialDocument } from '../types';
import { Category } from '../types';
import { isDocumentWithTitle } from '../typeValidators';
// eslint-disable-next-line import/no-cycle
import getDocumentOverrideOrReal from './getDocumentOverrideOrReal';

const defaultValue = 'document';

export default (
  doc?: AnyDocument | PartialDocument<AnyDocument, 'category' | 'title' | 'overrides'> | null | void,
  defaultValueParam?: string,
): string => {
  if (!doc) {
    return defaultValueParam ?? defaultValue;
  }

  if (doc.category === Category.Highlight) {
    return 'Highlight';
  }

  if (doc.category === Category.Note) {
    return 'Note';
  }

  if (isDocumentWithTitle(doc)) {
    return getDocumentOverrideOrReal(doc, 'title') ?? defaultValueParam ?? defaultValue;
  }

  return defaultValueParam ?? defaultValue;
};
