import React from 'react';

import forwardRef from '../../../shared/foreground/utils/forwardRef';
import styles from './TextLoadingIndicator.module.css';

// eslint-disable-next-line @typescript-eslint/ban-types, no-empty-pattern
export default forwardRef<{}, HTMLDivElement>(({}, ref) => {
  // eslint-disable-next-line react/no-array-index-key
  const blocks = new Array(200).fill(null).map((_, index) => <span key={index} />);
  return (
    <div ref={ref}>
      <p className="hideAccessibly">Loading...</p>
      <p aria-hidden className={styles.loadingIndicator}>
        {blocks}
      </p>
    </div>
  );
});
