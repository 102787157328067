import { parseFromTokens } from '../../../shared/filters-compiler/parser';
import { tokenizer } from '../../../shared/filters-compiler/tokenizer';

export const isValidQuery = (query: string): boolean => {
  const decodedQuery = decodeURIComponent(query);
  const { tokens: filterTokens, errorMessage: tokenizerErrorMessage } = tokenizer(decodedQuery);
  const { errorMessage: parserErrorMessage } = parseFromTokens(filterTokens ?? []);

  return !tokenizerErrorMessage && !parserErrorMessage;
};
