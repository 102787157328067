import React, { useEffect, useState } from 'react';

type Props = {
  amount: number;
  children?: JSX.Element | JSX.Element[];
  onMount?: () => void;
};

export default React.memo(function DelayMount({ amount, children, onMount }) {
  const [hasDelayEnded, setHasDelayEnded] = useState(amount === 0);

  useEffect(() => {
    if (amount === 0) {
      return;
    }
    let wasCleanupCalled = false;
    setTimeout(() => {
      if (!wasCleanupCalled) {
        setHasDelayEnded(true);
      }
    }, amount);

    return () => {
      wasCleanupCalled = true;
    };
  }, [amount]);

  useEffect(() => {
    if (!onMount || !hasDelayEnded) {
      return;
    }

    onMount();
  }, [hasDelayEnded, onMount]);

  return children && hasDelayEnded ? children : null;
} as React.FC<Props>);
