import { AnyDocument } from '../../types';
import { exportHighlights } from './export';

export const exportHighlightsToFile = async (documentId: AnyDocument['id']): Promise<void> => {
  const md = await exportHighlights(documentId);
  const link = document.createElement('a');
  const file = new Blob([md], { type: 'text/plain' });
  link.href = URL.createObjectURL(file);
  link.download = 'export.md';
  link.click();
  URL.revokeObjectURL(link.href);
};
