import React from 'react';

import Icon from './Icon';

export default function MinusCheckboxIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="MinusCheckboxIcon">
      <svg
        className={className}
        width="10"
        height="2"
        viewBox="0 0 10 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 1C0 0.447715 0.447715 0 1 0H9C9.55229 0 10 0.447715 10 1C10 1.55228 9.55229 2 9 2H1C0.447715 2 0 1.55228 0 1Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
}
