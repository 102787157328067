import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getLibraryRxDBQuery } from '../../../shared/filters-compiler/convertQueryToRxDBQuery';
import { getListId, globalState } from '../../../shared/foreground/models';
import { useCurrentSortRule, useDocument } from '../../../shared/foreground/stateHooks';
import { setHighlightIdToOpenAt } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import getNextDocumentLocation from '../../../shared/foreground/utils/getNextDocumentLocation';
import useDocumentLocations from '../../../shared/foreground/utils/useDocumentLocations';
import { DocumentLocation, FeedDocumentLocation } from '../../../shared/types';
import { ShortcutId } from '../../../shared/types/keyboardShortcuts';
import { isDocumentWithParent } from '../../../shared/typeValidators';
import nowTimestamp from '../../../shared/utils/dates/nowTimestamp';
import urlJoin from '../../../shared/utils/urlJoin';
import useDocumentIdsForDocumentList from '../hooks/useDocumentIdsForDocumentList';
import { useKeyboardShortcut } from '../hooks/useKeyboardShortcut';
import { reactLazy } from '../utils/dynamicImport';
import focusFirstFocusableDescendant from '../utils/focusFirstFocusableDescendant';
import { useShortcutsMap } from '../utils/shortcuts';
import { DeleteAllSeenButton } from './DeleteAllSeenButton';
import styles from './DocumentInbox.module.css';
import DocumentListLoaderSkeleton from './DocumentListLoaderSkeleton';
import { FloatingPill } from './FloatingPill';
import InboxHeader from './InboxHeader';
import { MarkAllSeenButton } from './MarkAllSeenButton';

const Documents = reactLazy(() => import('./Documents'));

interface DocumentInboxProps {
  documentPathPrefix: string;
  filterByFeedDocumentLocation?: FeedDocumentLocation;
  filterByDocumentLocation?: DocumentLocation;
  incomingOpenDocumentId?: string;
  parentPath: string;
  uncategorizedDocumentIdToOpen?: string;
}
export const DocumentInbox = React.memo(function DocumentInbox({
  documentPathPrefix,
  filterByFeedDocumentLocation,
  filterByDocumentLocation,
  incomingOpenDocumentId,
  parentPath,
  uncategorizedDocumentIdToOpen,
}: DocumentInboxProps) {
  const history = useHistory();
  const isDocumentMetadataShown = globalState(useCallback((state) => state.isDocumentMetadataShown, []));
  const openDocumentId = useMemo(
    () => uncategorizedDocumentIdToOpen ?? incomingOpenDocumentId,
    [uncategorizedDocumentIdToOpen, incomingOpenDocumentId],
  );
  const [openDocument, { isFetching: isFetchingOpenDocument }] = useDocument(openDocumentId);
  const isFeed = filterByDocumentLocation === DocumentLocation.Feed;
  const isFeedUnseen = isFeed && filterByFeedDocumentLocation === FeedDocumentLocation.New;
  const isFeedSeen = isFeed && filterByFeedDocumentLocation === FeedDocumentLocation.Seen;
  const shortcutsMap = useShortcutsMap();

  const documentInboxRef = useRef(null);

  const documentLocations = useDocumentLocations();
  const isPersistentStateLoaded = globalState(useCallback((state) => state.persistentStateLoaded, []));

  // If they go to /new[...] when inbox is disabled (for example), bring them to a valid URL
  useEffect(() => {
    if (!filterByDocumentLocation || !isPersistentStateLoaded) {
      return;
    }

    const potentialDocumentLocation = parentPath.replace('/', '');
    if (!Object.values(DocumentLocation).includes(potentialDocumentLocation as DocumentLocation)) {
      return;
    }

    const documentLocation = potentialDocumentLocation as DocumentLocation;
    if (!documentLocations.includes(documentLocation)) {
      history.replace({
        pathname: '/library',
      });
    }
  }, [documentLocations, filterByDocumentLocation, history, isPersistentStateLoaded, parentPath]);

  const listId = getListId({
    filterByDocumentLocation,
    filterByFeedDocumentLocation,
  });

  const currentSortRule = useCurrentSortRule({
    filterByFeedDocumentLocation,
    filterByDocumentLocation,
    listId,
  });
  const [currentTimestamp, setCurrentTimestamp] = useState(nowTimestamp());
  useEffect(() => {
    const newTimestamp = nowTimestamp();
    if (filterByFeedDocumentLocation && Math.abs(newTimestamp - currentTimestamp) > 500) {
      setCurrentTimestamp(newTimestamp);
    }
  }, [currentTimestamp, filterByDocumentLocation, filterByFeedDocumentLocation]);

  const createMangoQuery = useCallback(() => {
    const { mangoQuery } = getLibraryRxDBQuery({
      filterByFeedDocumentLocation,
      filterByDocumentLocation,
      sortRules: [currentSortRule],
      firstOpenedBefore: currentTimestamp - 1,
    });
    return mangoQuery;
  }, [currentSortRule, currentTimestamp, filterByDocumentLocation, filterByFeedDocumentLocation]);

  const mangoQuery = useMemo(createMangoQuery, [createMangoQuery]);

  const {
    documentIds,
    fetchMore,
    pageSize,
    setHasInitialFocusIndicatorLocationBeenDetermined,
    shouldShowSkeleton,
    totalCount,
  } = useDocumentIdsForDocumentList({
    createMangoQuery,
    openDocumentId,
  });

  useEffect(() => {
    setHasInitialFocusIndicatorLocationBeenDetermined(false);
  }, [
    filterByFeedDocumentLocation,
    filterByDocumentLocation,
    isFeed,
    setHasInitialFocusIndicatorLocationBeenDetermined,
  ]);

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.NextSplit],
    useCallback(
      (event) => {
        // We don't want to run this shortcut if we are on the document
        // reader view or editing metadata
        if (openDocumentId || isDocumentMetadataShown) {
          return;
        }

        if (!filterByDocumentLocation) {
          return;
        }

        event.preventDefault();
        const nextDocumentLocation = getNextDocumentLocation({
          currentDocumentLocation: filterByDocumentLocation,
          documentLocations,
          currentFeedDocumentLocation: filterByFeedDocumentLocation,
          direction: 1,
        });

        if (isFeed) {
          history.push(urlJoin(['/', DocumentLocation.Feed, nextDocumentLocation]));
        } else {
          history.push(urlJoin(['/', nextDocumentLocation]));
        }
      },
      [
        documentLocations,
        filterByDocumentLocation,
        filterByFeedDocumentLocation,
        history,
        isDocumentMetadataShown,
        isFeed,
        openDocumentId,
      ],
    ),
    {
      description: 'Cycle forward through splits',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.PreviousSplit],
    useCallback(
      (event) => {
        // We don't want to run this shortcut if we are on the document
        // reader view or editing metadata
        if (openDocumentId || isDocumentMetadataShown) {
          return;
        }

        if (!filterByDocumentLocation) {
          return;
        }

        event.preventDefault();

        const nextDocumentLocation = getNextDocumentLocation({
          currentDocumentLocation: filterByDocumentLocation,
          documentLocations,
          currentFeedDocumentLocation: filterByFeedDocumentLocation,
          direction: -1,
        });
        history.push(urlJoin(['/', isFeed ? DocumentLocation.Feed : '', nextDocumentLocation]));
      },
      [
        documentLocations,
        filterByDocumentLocation,
        filterByFeedDocumentLocation,
        history,
        isDocumentMetadataShown,
        isFeed,
        openDocumentId,
      ],
    ),
    {
      description: 'Cycle backward through splits',
    },
  );

  useEffect(() => {
    // Regrab focus when we leave a document view. This is needed for PDF iframes to return focus to the app
    if (openDocumentId || !documentInboxRef.current) {
      return;
    }

    const descendent = focusFirstFocusableDescendant(documentInboxRef.current);
    setTimeout(() => {
      descendent?.blur();
    }, 1);
  }, [openDocumentId]);

  useEffect(() => {
    if (isFetchingOpenDocument) {
      return;
    }

    if (!openDocument) {
      setHighlightIdToOpenAt(null, { userInteraction: 'unknown' });
      return;
    }

    if (isDocumentWithParent(openDocument) && openDocument.parent && openDocument.id) {
      setHighlightIdToOpenAt(openDocument.id, { userInteraction: 'unknown' });
      history.push(urlJoin(['/read', openDocument.parent]));
    }
  }, [isFetchingOpenDocument, history, openDocument]);

  const docComponent = (
    <Suspense fallback={null}>
      {isFeedUnseen && mangoQuery && <MarkAllSeenButton mangoQuery={mangoQuery} />}
      {isFeedSeen && mangoQuery && <DeleteAllSeenButton mangoQuery={mangoQuery} />}

      <FloatingPill>
        <>Count: {`${totalCount ?? '...'}`}</>
      </FloatingPill>
      <Documents
        currentSortRule={currentSortRule}
        documentPathPrefix={documentPathPrefix}
        key={parentPath}
        listedDocumentIds={documentIds}
        openDocumentId={openDocumentId}
        parentPath={parentPath}
        onEndThresholdReached={fetchMore}
        pageSize={pageSize}
      />
    </Suspense>
  );

  return (
    <Suspense fallback={null}>
      <div
        className={`${styles.inbox} ${openDocumentId ? styles.inboxWithOpenDocument : ''}`}
        id="document-inbox"
        ref={documentInboxRef}
      >
        <div className={styles.inboxContainer}>
          {!openDocumentId && <InboxHeader isFeed={isFeed} listId={listId} />}
          {shouldShowSkeleton ? <DocumentListLoaderSkeleton /> : docComponent}
        </div>
      </div>
    </Suspense>
  );
});
