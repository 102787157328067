import React from 'react';

import Icon from './Icon';

export default function CheckIcon({
  className = '',
  text = 'Checked',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7071 4.79289C13.0976 5.18342 13.0976 5.81658 12.7071 6.20711L7.70711 11.2071C7.31658 11.5976 6.68342 11.5976 6.29289 11.2071L4.29289 9.20711C3.90237 8.81658 3.90237 8.18342 4.29289 7.79289C4.68342 7.40237 5.31658 7.40237 5.70711 7.79289L7 9.08579L11.2929 4.79289C11.6834 4.40237 12.3166 4.40237 12.7071 4.79289Z"
          fill="#F9F9FA"
        />
      </svg>
    </Icon>
  );
}
