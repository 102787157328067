import { AnyDocument, RssFeed } from '../../types';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import { globalState } from '../models';
import getViewsByFeedId from './getViewsByFeedId';

export const DEFAULT_EXPORT_TEMPLATE = `# {{ title }}

## Metadata
- Author: {{author}}
- Category: {{category}}
{% if document_note -%}
- Document Note: {{document_note}}
{% endif -%}
{% if tags -%}
- Document Tags: {% for tag in tags %}{{tag}} {% endfor %}
{% endif -%}
{% if url -%}
- URL: {{url}}
{% endif -%}

## Highlights
{% for highlight in highlights %}
{{highlight.content}}
{% if highlight.note -%}Note: {{highlight.note}}{% endif -%}
{% if highlight.tags -%}Tags: {% for tag in highlight.tags %}{{tag}}{% endfor %}{% endif -%}
{% endfor %}
`;

export const exportDocuments = async () => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/export_everything/`, {
    credentials: 'include',
    method: 'GET',
    mode: 'cors',
  });
  return resp.text();
};

export const exportFiles = async () => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/export_uploaded_files/`, {
    credentials: 'include',
    method: 'GET',
    mode: 'cors',
  });
  return resp.text();
};

export const exportHighlights = async (docId: AnyDocument['id']) => {
  const exportTemplate =
    globalState.getState().persistent?.settings?.exportClipboardTemplate ?? DEFAULT_EXPORT_TEMPLATE;
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/export_document/${docId}`, {
    body: exportTemplate,
    credentials: 'include',
    method: 'POST',
    mode: 'cors',
  });
  return resp.text();
};

export const exportOPML = async () => {
  const rssFeeds = globalState.getState().persistent?.rssFeeds;
  const filteredViews = globalState.getState().persistent?.filteredViews;
  if (rssFeeds) {
    const rssKeys = Object.keys(rssFeeds);
    const viewsByFeedId = getViewsByFeedId(filteredViews, rssKeys);
    const opmlTpl = (feeds: [string, RssFeed][]) => `<?xml version="1.0" encoding="UTF-8"?>
<opml version="1.0">
<head>
  <title>My feeds</title>
</head>
<body>
<outline text="Feeds" title="Feeds">
${feeds
  .map((feedPair) => {
    let views: string | null;
    const feedId = feedPair[0];
    const feed = feedPair[1];
    if (feedId && viewsByFeedId[feedId]) {
      views = `category="${viewsByFeedId[feedId].map((view) => view.name).join(',')}"`;
    } else {
      views = null;
    }
    const feedName = feed.name?.replaceAll('"', '\\"');
    return `  <outline title="${feedName}" ${views || ''} type="rss" xmlUrl="${feed.url}" />`;
  })
  .join('\n')}
</outline>
</body>
</opml>`;
    return opmlTpl(Object.entries(rssFeeds));
  } else {
    return null;
  }
};

export const templateValidation = async (exportTemplate: string) => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/export_template_validation/`, {
    body: exportTemplate,
    credentials: 'include',
    method: 'POST',
    mode: 'cors',
  });
  return resp.json();
};

export const exportAllFiles = async () => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/export_full_data/`, {
    credentials: 'include',
    method: 'GET',
    mode: 'cors',
  });
  return resp.text();
};
