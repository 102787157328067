import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import ttsController from '../../../shared/foreground/actions/ttsController.platform';
import { globalState } from '../../../shared/foreground/models';
import {
  useCurrentTTSLanguageForDoc,
  useCurrentTTSVoiceForDoc,
  useDocument,
  useRssSourceNameForDoc,
} from '../../../shared/foreground/stateHooks';
import { useFocusedDocumentId } from '../../../shared/foreground/stateHooks/useFocusedDocument';
import useOpenDocumentId from '../../../shared/foreground/stateHooks/useOpenDocumentId';
import combineClasses from '../../../shared/foreground/utils/combineClasses';
import {
  getTextToSpeechDisplayName,
  textToSpeechDefaultPlaybackRate,
  textToSpeechDefaultVolume,
} from '../../../shared/foreground/utils/tts';
import useGlobalStateWithFallback from '../../../shared/foreground/utils/useGlobalStateWithFallback';
import { type FirstClassDocument, Category } from '../../../shared/types';
import { type KeyboardShortcut, ShortcutId } from '../../../shared/types/keyboardShortcuts';
import type { TextToSpeechInfo, TextToSpeechVoice, TTSLanguage } from '../../../shared/types/tts';
import {
  PlaybackRates,
  TextToSpeechVoicesByLanguage,
  TextToSpeechVoiceToApiVersion,
} from '../../../shared/types/tts';
import { isDocumentWithThirdPartyUrl } from '../../../shared/typeValidators';
import getDocumentDomain from '../../../shared/utils/getDocumentDomain';
import getDocumentLanguageDisplayName from '../../../shared/utils/getDocumentLanguageDisplayName';
import getDocumentTitle from '../../../shared/utils/getDocumentTitle';
import getUrlDomain from '../../../shared/utils/getUrlDomain';
import urlJoin from '../../../shared/utils/urlJoin';
import { useKeyboardShortcut } from '../hooks/useKeyboardShortcut';
import getNumericCssPropertyValue from '../utils/getNumericCssPropertyValue';
import playOrStopTtsFromCurrentScrollPosition from '../utils/playOrStopTtsFromCurrentScrollPosition';
import { useShortcutsMap } from '../utils/shortcuts';
import Button from './Button';
import { DocumentListCoverImage } from './CoverImage/DocumentListCoverImage';
import { Dropdown, DropdownOption, DropdownOptionType } from './Dropdown/Dropdown';
import LargePauseIcon from './icons/LargePauseIcon';
import LargePlayIcon from './icons/LargePlayIcon';
import LargeSkipBackwardIcon from './icons/LargeSkipBackwardIcon';
import LargeSkipForwardIcon from './icons/LargeSkipForwardIcon';
import MutedSpeakerIcon from './icons/MutedSpeakerIcon';
import SpeakerIcon from './icons/SpeakerIcon';
import StrokeCancelIcon from './icons/StrokeCancelIcon';
import WaveformIcon from './icons/WaveformIcon';
import Slider from './Slider';
import Spinner from './Spinner';
import Tooltip from './Tooltip';
import styles from './TtsPlayer.module.css';

const allTtsLanguagesSorted = sortBy(
  Object.keys(TextToSpeechVoicesByLanguage) as TTSLanguage[],
  getDocumentLanguageDisplayName,
);

function convertTimePositionToDecimal(input: number, duration: number): number {
  return input && duration ? input / duration : 0;
}

function formatSecondsAsTime(input = 0): string {
  const fixedInput = isNaN(input) ? 0 : input;

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(fixedInput / 3600);
  const minutes = Math.floor((fixedInput % 3600) / 60);
  const seconds = Math.floor(fixedInput % 60);

  const resultSegments: (string | number)[] = [];
  if (hours > 0) {
    resultSegments.push(hours);
  }
  resultSegments.push(minutes, zeroPad(seconds));
  return resultSegments.join(':');
}

function useKeyboardShortcuts({
  isVisible,
  tts,
}: {
  isVisible: boolean;
  tts: TextToSpeechInfo | null;
}) {
  // These may be unrelated to anything playing
  const focusedDocumentId = useFocusedDocumentId();
  const openDocumentId = useOpenDocumentId();

  const shortcutsMap = useShortcutsMap();

  const descriptionsUsedMoreThanOnce = {
    [ShortcutId.Stop]: 'Stop and hide text-to-speech player',
    [ShortcutId.SkipBackwards]: 'Skip backward in text-to-speech audio',
    [ShortcutId.SkipForward]: 'Skip forward in text-to-speech audio',
  };

  const wrapShortcutHandler = useCallback(
    (
      callback: KeyboardShortcut['callback'],
      shouldPreventDefault = true,
    ): KeyboardShortcut['callback'] => {
      return (event) => {
        if (!isVisible) {
          return;
        }
        if (shouldPreventDefault) {
          event.preventDefault();
        }
        callback(event);
      };
    },
    [isVisible],
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.PlayOrPause],
    useCallback(
      (event) => {
        if (!focusedDocumentId) {
          return;
        }
        event.preventDefault();
        if (tts?.playingDocId) {
          ttsController.resumeOrPauseCurrentlyPlayingDocument();
        } else if (openDocumentId) {
          playOrStopTtsFromCurrentScrollPosition({
            currentlyOpenDocId: openDocumentId,
          });
        } else {
          ttsController.playDocument(focusedDocumentId);
        }
      },
      [focusedDocumentId, openDocumentId, tts?.playingDocId],
    ),
    { description: 'Play / pause text-to-speech' },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.Stop],
    wrapShortcutHandler(ttsController.stop.bind(ttsController)),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.Stop] },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.SkipBackwards],
    wrapShortcutHandler(ttsController.jumpBackward.bind(ttsController)),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.SkipBackwards] },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.SkipForward],
    wrapShortcutHandler(ttsController.jumpForward.bind(ttsController)),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.SkipForward] },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.SpeedUpPlayback],
    wrapShortcutHandler(() =>
      ttsController.increasePlaybackRatePreference({ userInteraction: 'keydown' }),
    ),
    {
      description: 'Speed up text-to-speech playback rate',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.SlowDownPlayBack],
    wrapShortcutHandler(() =>
      ttsController.decreasePlaybackRatePreference({ userInteraction: 'keydown' }),
    ),
    {
      description: 'Slow down text-to-speech playback rate',
    },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.IncreaseVolume],
    wrapShortcutHandler((event) => {
      if (window.getSelection()?.toString()) {
        return;
      }
      event.preventDefault();
      return ttsController.modifyVolumePreference('increase', {
        userInteraction: 'keydown',
      });
    }, false),
    { description: 'Increase volume' },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.DecreaseVolume],
    wrapShortcutHandler((event) => {
      if (window.getSelection()?.toString()) {
        return;
      }
      event.preventDefault();
      return ttsController.modifyVolumePreference('decrease', {
        userInteraction: 'keydown',
      });
    }, false),
    { description: 'Decrease volume' },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.MediaPlay],
    useCallback(() => {
      ttsController.playOrPauseCurrentlyPlayingDocumentOrPlayNewDocument(focusedDocumentId);
    }, [focusedDocumentId]),
    { description: 'Play text-to-speech', isEnabled: Boolean(focusedDocumentId || tts?.playingDocId) },
  );

  useKeyboardShortcut(shortcutsMap[ShortcutId.MediaPause], ttsController.pause.bind(ttsController), {
    description: 'Pause text-to-speech',
    isEnabled: isVisible,
  });

  useKeyboardShortcut(shortcutsMap[ShortcutId.MediaStop], ttsController.stop.bind(ttsController), {
    description: descriptionsUsedMoreThanOnce[ShortcutId.Stop],
    isEnabled: isVisible,
  });

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.MediaPreviousTrack],
    ttsController.jumpBackward.bind(ttsController),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.SkipBackwards], isEnabled: isVisible },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.MediaSeekBackward],
    ttsController.jumpBackward.bind(ttsController),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.SkipBackwards], isEnabled: isVisible },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.MediaSeekForward],
    ttsController.jumpForward.bind(ttsController),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.SkipForward], isEnabled: isVisible },
  );

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.MediaNextTrack],
    ttsController.jumpForward.bind(ttsController),
    { description: descriptionsUsedMoreThanOnce[ShortcutId.SkipForward], isEnabled: isVisible },
  );
}

function zeroPad(input: number) {
  return String(input).padStart(2, '0');
}

function PlayrateSetting({
  onOptionSelected,
  playrate,
}: {
  onOptionSelected: (rate: number) => void;
  playrate: number;
}) {
  const currentPlayrateCleaned = playrate % 1 === 0 ? playrate : parseFloat(playrate.toFixed(2));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const options = useMemo(() => {
    return PlaybackRates.map((rate) => ({
      checked: currentPlayrateCleaned === rate,
      name: `${rate}×`,
      onSelect: () => onOptionSelected(rate),
      type: DropdownOptionType.Item,
    }));
  }, [currentPlayrateCleaned, onOptionSelected]);

  const dropdownTriggerButton = (
    <DropdownMenu.Trigger asChild>
      <Button className={styles.playrateButton} variant="secondary">
        {currentPlayrateCleaned}&times;
      </Button>
    </DropdownMenu.Trigger>
  );

  return (
    <Dropdown
      contentClassName={styles.playrateDropdown}
      isOpen={isDropdownOpen}
      options={options}
      setIsOpen={setIsDropdownOpen}
      trigger={dropdownTriggerButton}
    />
  );
}

function TrackInfo({
  docId,
}: {
  docId?: FirstClassDocument['id'];
}) {
  const [doc] = useDocument(docId); // TODO
  const rssSourceName = useRssSourceNameForDoc(doc);

  let contents = null;

  if (doc) {
    const titleToShow = getDocumentTitle(doc) || '[No title]';

    let siteNameOrDomainInfo: JSX.Element | undefined;
    if (![Category.EPUB, Category.PDF].includes(doc.category)) {
      const originUrl = isDocumentWithThirdPartyUrl(doc) ? getUrlDomain(doc.url) : undefined;
      const domainOrName = getDocumentDomain({
        rssSourceName,
        siteName: doc.site_name,
        originUrl,
      });

      if (domainOrName) {
        siteNameOrDomainInfo = <p className={styles.trackSourceName}>{domainOrName}</p>;

        // Should we link to the domain (filter page)?
        if ([doc.site_name, originUrl].includes(domainOrName)) {
          siteNameOrDomainInfo = (
            <Link to={`/filter/domain:%22${domainOrName}%22`}>{siteNameOrDomainInfo}</Link>
          );
        }
      }
    }

    const documentLinkUrl = urlJoin(['/read', docId]);

    contents = (
      <>
        <Link to={documentLinkUrl}>
          <DocumentListCoverImage category={doc.category} imageUrl={doc.image_url ?? undefined} />
        </Link>
        <div className={styles.trackInfoText}>
          <Link to={documentLinkUrl}>
            <p className={styles.trackTitle}>{titleToShow}</p>
          </Link>
          {siteNameOrDomainInfo}
        </div>
      </>
    );
  }

  return <div className={styles.trackInfo}>{contents}</div>;
}

function VoiceSetting({
  docId,
  onOptionSelected,
}: {
  docId: FirstClassDocument['id'];
  onOptionSelected: (language: TTSLanguage, voiceId: TextToSpeechVoice) => void;
}) {
  const currentVoiceId = useCurrentTTSVoiceForDoc(docId);
  const currentTtsLanguage = useCurrentTTSLanguageForDoc(docId);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [areOptionsLimitedToCurrentTtsLanguage, setAreOptionsLimitedToCurrentTtsLanguage] =
    useState(true);

  const options = useMemo(() => {
    const languagesToShow = areOptionsLimitedToCurrentTtsLanguage
      ? [currentTtsLanguage]
      : allTtsLanguagesSorted;
    const options: DropdownOption[] = languagesToShow
      .map((language) => {
        return [
          {
            name: getDocumentLanguageDisplayName(language),
            type: DropdownOptionType.Title,
          },
          ...TextToSpeechVoicesByLanguage[language].map((voiceId) => {
            const name = getTextToSpeechDisplayName(voiceId);
            let displayNameSuffix: JSX.Element | undefined;
            if (TextToSpeechVoiceToApiVersion[voiceId] === 'v3') {
              displayNameSuffix = (
                <span className={styles.voiceDropdownOptionSuffix}>Powered by Unreal Speech</span>
              );
            }

            return {
              checked: currentVoiceId === voiceId,
              name,
              nameNode: (
                <span className={styles.voiceDropdownOption}>
                  <span className={styles.voiceDropdownOptionName}>{name}</span>
                  {displayNameSuffix}
                </span>
              ),
              onSelect: () => onOptionSelected(language, voiceId),
              type: DropdownOptionType.Item,
            };
          }),
        ];
      })
      .flat();

    if (areOptionsLimitedToCurrentTtsLanguage) {
      options.push(
        {
          checked: false,
          name: '',
          onSelect: () => {},
          type: DropdownOptionType.Separator,
        },
        {
          checked: false,
          name: 'View all languages',
          onSelect: (event) => {
            event.preventDefault();
            setAreOptionsLimitedToCurrentTtsLanguage(false);
          },
          type: DropdownOptionType.Item,
        },
      );
    }

    return options;
  }, [areOptionsLimitedToCurrentTtsLanguage, currentTtsLanguage, currentVoiceId, onOptionSelected]);

  useEffect(() => {
    if (isDropdownOpen) {
      return;
    }
    setAreOptionsLimitedToCurrentTtsLanguage(true);
  }, [isDropdownOpen]);

  const dropdownTriggerButton = (
    <DropdownMenu.Trigger asChild>
      <Button className={styles.voicesTriggerButton} variant="secondary">
        <WaveformIcon text="Voice" />
        {getTextToSpeechDisplayName(currentVoiceId)}
      </Button>
    </DropdownMenu.Trigger>
  );

  return (
    <Dropdown
      contentClassName={combineClasses(styles.voiceDropdown, 'has-visible-scrollbar')}
      isOpen={isDropdownOpen}
      options={options}
      setIsOpen={setIsDropdownOpen}
      trigger={dropdownTriggerButton}
    />
  );
}

function VolumeSetting({
  lastNonZeroVolumeRef,
  onChanged,
  volume,
}: {
  lastNonZeroVolumeRef: React.MutableRefObject<number>;
  onChanged: (newValue: number, wasUnmuteClicked?: boolean) => void;
  volume: number;
}) {
  const icon = volume === 0 ? <MutedSpeakerIcon /> : <SpeakerIcon />;

  const onClickIcon = useCallback(() => {
    onChanged(volume ? 0 : lastNonZeroVolumeRef.current, volume === 0);
  }, [lastNonZeroVolumeRef, onChanged, volume]);

  return (
    <div className={styles.volume}>
      <Button className={styles.toggleMuteButton} onClick={onClickIcon} variant="unstyled">
        {icon}
      </Button>
      <Slider
        className={styles.volumeSlider}
        onValueChanged={onChanged}
        value={volume}
        valueLabel={Math.round(volume * 100).toString()}
      />
    </div>
  );
}

export default function TtsPlayer() {
  const tts = globalState((state) => state.tts);
  const isVisible = useMemo(() => Boolean(tts), [tts]);

  const shortcutMap = useShortcutsMap();

  const audioRef = useRef<HTMLAudioElement>();

  const [isPlaying, setIsPlaying] = useState(true);
  const [isSpinnerShown, setIsSpinnerShown] = useState(false);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [bufferedRanges, setBufferedRanges] = useState<{ start: number; end: number }[]>([]);
  const currentTimeAsDecimal = useMemo(
    () => convertTimePositionToDecimal(currentTime, duration),
    [currentTime, duration],
  );

  const playrate = useGlobalStateWithFallback(
    textToSpeechDefaultPlaybackRate,
    (state) => state.persistent.settings.tts_v2?.playbackRate,
  );
  const volume = useGlobalStateWithFallback(
    textToSpeechDefaultVolume,
    (state) => state.persistent.settings.tts_v2?.volume,
  );
  const lastNonZeroVolumeRef = useRef(volume > 0 ? volume : 1);

  const updateCurrentTime = useCallback((newValue) => {
    if (!audioRef.current?.duration) {
      return;
    }
    audioRef.current.currentTime = newValue * audioRef.current.duration;
  }, []);

  const setPlayrate = useCallback((newValue) => {
    ttsController.setPlaybackRatePreference(newValue, {
      userInteraction: 'unknown',
    });
  }, []);

  const onNewVoiceChosen = useCallback(
    (language: TTSLanguage, voiceId: TextToSpeechVoice) => {
      if (!tts?.playingDocId) {
        return;
      }
      ttsController.setVoicePreferenceAndLanguage({
        documentId: tts.playingDocId,
        language,
        voice: voiceId,
        userInteraction: 'unknown',
      });
    },
    [tts?.playingDocId],
  );

  const onVolumeChanged = useCallback((newValue: number, wasUnmuteClicked?: boolean) => {
    let fixedNewValue = newValue;
    if (wasUnmuteClicked) {
      /*
        When unmuting, we set the volume to the last non-zero volume. But that could be 0.02 if they dragged the volume
        slider slowly.
      */
      fixedNewValue = Math.max(newValue, 0.3);
    }
    ttsController.setVolumePreference(fixedNewValue, {
      userInteraction: 'unknown',
    });
    if (wasUnmuteClicked) {
      ttsController.play();
    }
  }, []);

  useEffect(() => {
    if (volume) {
      lastNonZeroVolumeRef.current = volume;
    }
  }, [volume]);

  useEffect(() => {
    const hideSpinner = () => setIsSpinnerShown(false);
    const onCurrentTimeUpdated = (event: Event) =>
      setCurrentTime((event.target as HTMLAudioElement).currentTime);
    const onDurationUpdated = (event: Event) =>
      setDuration((event.target as HTMLAudioElement).duration ?? 0);
    const onPaused = () => setIsPlaying(false);
    const onPlay = () => setIsPlaying(true);
    const onProgress = (event: ProgressEvent) => {
      const audio = event.target as HTMLAudioElement;
      const ranges: { start: number; end: number }[] = [];
      for (let i = 0; i < audio.buffered.length; i++) {
        ranges.push({
          start: convertTimePositionToDecimal(audio.buffered.start(i), audio.duration),
          end: convertTimePositionToDecimal(audio.buffered.end(i), audio.duration),
        });
      }
      setBufferedRanges(ranges);
    };
    const onSeeking = (event: Event) => {
      onCurrentTimeUpdated(event);
      setIsSpinnerShown(true);
    };

    (async () => {
      await ttsController.trackPlayerCreationPromise;
      audioRef.current = (document.getElementById('tts-player') as HTMLAudioElement | null) ?? undefined;
      if (!audioRef.current) {
        throw new Error("Can't attach to TTS audio element");
      }
      audioRef.current.addEventListener('canplay', hideSpinner);
      audioRef.current.addEventListener('durationchange', onDurationUpdated);
      audioRef.current.addEventListener('pause', onPaused);
      audioRef.current.addEventListener('play', onPlay);
      audioRef.current.addEventListener('playing', hideSpinner);
      audioRef.current.addEventListener('progress', onProgress);
      audioRef.current.addEventListener('seeking', onSeeking);
      audioRef.current.addEventListener('timeupdate', onCurrentTimeUpdated);
    })();

    return () => {
      if (!audioRef.current) {
        return;
      }
      audioRef.current.removeEventListener('canplay', hideSpinner);
      audioRef.current.removeEventListener('durationchange', onDurationUpdated);
      audioRef.current.removeEventListener('pause', onPaused);
      audioRef.current.removeEventListener('play', onPlay);
      audioRef.current.removeEventListener('playing', hideSpinner);
      audioRef.current.removeEventListener('progress', onProgress);
      audioRef.current.removeEventListener('seeking', onSeeking);
      audioRef.current.removeEventListener('timeupdate', onCurrentTimeUpdated);
      audioRef.current = undefined;
    };
  }, [audioRef]);

  useEffect(() => {
    const newValue: number = isVisible ? getNumericCssPropertyValue('--tts-player-height') : 0;

    // The unit here is neccessary
    document.documentElement.style.setProperty('--js__tts-player-current-height', `${newValue}px`);
  }, [isVisible]);

  useEffect(() => {
    ttsController.setTrackPlayerInfo({
      position: currentTime,
      isPlaying,
    });
  }, [currentTime, isPlaying]);

  // It's important that this is always called
  useKeyboardShortcuts({ isVisible, tts });

  const timelineBufferedRanges = useMemo(() => {
    /*
      Why do we filter these?

      Imagine you start playing from 65% (position). You see a buffer bar element appear to the right. Then you click on
      25% and you see a buffer bar element appear to the right of that. How should it look?

      A: ###O==------====--
      B: ###O==------------

      We're going with B like YouTube does.
    */
    const filteredRanges: typeof bufferedRanges = [];
    let deepestAllowedEndPosition = currentTimeAsDecimal;
    for (const bufferedRange of bufferedRanges) {
      if (bufferedRange.start > deepestAllowedEndPosition) {
        break;
      }
      filteredRanges.push(bufferedRange);
      deepestAllowedEndPosition = Math.max(deepestAllowedEndPosition, bufferedRange.end);
    }

    if (!filteredRanges.length) {
      return null;
    }

    // Note: I would've used an `<ol>` but it's going inside a `<span>` inside the slider anyway
    const items = filteredRanges.map((range) => (
      <span
        className={styles.timelineBufferedRange}
        key={range.start + range.end}
        style={{
          left: `${range.start * 100}%`,
          right: `${(1 - range.end) * 100}%`,
        }}
      />
    ));
    return <span className={styles.timelineBufferedRanges}>{items}</span>;
  }, [currentTimeAsDecimal, bufferedRanges]);

  if (!isVisible || !tts) {
    return null;
  }

  const playButtonIcon = isPlaying ? <LargePauseIcon /> : <LargePlayIcon />;

  const spinnerClasses = [styles.spinner];
  if (isSpinnerShown) {
    spinnerClasses.push(styles.spinnerShown);
  }

  return (
    <aside className={styles.ttsPlayer}>
      <TrackInfo docId={tts?.playingDocId} />

      <div className={styles.main}>
        <div className={styles.mainButtons}>
          <Tooltip content="Jump backward" shortcut={shortcutMap[ShortcutId.SkipBackwards]}>
            <Button onClick={ttsController.jumpBackward.bind(ttsController)} variant="unstyled">
              <LargeSkipBackwardIcon />
            </Button>
          </Tooltip>

          <Tooltip content="Play / pause" shortcut={shortcutMap[ShortcutId.PlayOrPause]}>
            <Button
              className={styles.playButton}
              onClick={ttsController.toggleIsPlaying.bind(ttsController)}
              variant="unstyled"
            >
              {playButtonIcon}
              <Spinner className={combineClasses(spinnerClasses)} />
            </Button>
          </Tooltip>

          <Tooltip content="Jump forward" shortcut={shortcutMap[ShortcutId.SkipForward]}>
            <Button onClick={ttsController.jumpForward.bind(ttsController)} variant="unstyled">
              <LargeSkipForwardIcon />
            </Button>
          </Tooltip>
        </div>

        <div className={styles.timeline}>
          <div className={styles.currentTime}>{formatSecondsAsTime(currentTime)}</div>
          <div className={styles.timelineSlider}>
            <Slider onValueChanged={updateCurrentTime} value={currentTimeAsDecimal} valueLabel="">
              {timelineBufferedRanges}
            </Slider>
          </div>
          <div className={styles.duration}>{formatSecondsAsTime(duration)}</div>
        </div>
      </div>

      <div className={styles.secondaryControls}>
        <div className={styles.settings}>
          <VolumeSetting
            lastNonZeroVolumeRef={lastNonZeroVolumeRef}
            onChanged={onVolumeChanged}
            volume={volume}
          />
          <PlayrateSetting onOptionSelected={setPlayrate} playrate={playrate} />
          <VoiceSetting docId={tts.playingDocId} onOptionSelected={onNewVoiceChosen} />
        </div>

        <Button
          className={styles.closeButton}
          onClick={ttsController.stop.bind(ttsController)}
          variant="secondary"
        >
          <StrokeCancelIcon text="Close" />
        </Button>
      </div>
    </aside>
  );
}
