import React from 'react';

import Icon from './Icon';

export default function SunAndMoonIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="SunAndMoonIcon">
      <svg
        className={className}
        width="60"
        height="32"
        viewBox="0 0 60 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 6.40625C14.4142 6.40625 14.75 6.74204 14.75 7.15625V10.2332C14.75 10.6474 14.4142 10.9832 14 10.9832C13.5858 10.9832 13.25 10.6474 13.25 10.2332V7.15625C13.25 6.74204 13.5858 6.40625 14 6.40625Z"
          fill="black"
        />
        <path
          d="M11.2947 13.2967C12.0124 12.579 12.9858 12.1758 14.0008 12.1758C15.0157 12.1758 15.9891 12.579 16.7068 13.2967C17.4245 14.0143 17.8277 14.9877 17.8277 16.0027C17.8277 17.0177 17.4245 17.9911 16.7068 18.7087C15.9891 19.4264 15.0157 19.8296 14.0008 19.8296C12.9858 19.8296 12.0124 19.4264 11.2947 18.7087C10.577 17.9911 10.1738 17.0177 10.1738 16.0027C10.1738 14.9877 10.577 14.0143 11.2947 13.2967Z"
          fill="black"
        />
        <path
          d="M14 21.0234C14.4142 21.0234 14.75 21.3592 14.75 21.7734V24.8504C14.75 25.2646 14.4142 25.6004 14 25.6004C13.5858 25.6004 13.25 25.2646 13.25 24.8504V21.7734C13.25 21.3592 13.5858 21.0234 14 21.0234Z"
          fill="black"
        />
        <path
          d="M7.21469 9.21586C7.50753 8.92291 7.98241 8.92283 8.27535 9.21567L10.4515 11.3911C10.7445 11.6839 10.7445 12.1588 10.4517 12.4517C10.1589 12.7447 9.68398 12.7447 9.39103 12.4519L7.21488 10.2765C6.92194 9.98368 6.92185 9.5088 7.21469 9.21586Z"
          fill="black"
        />
        <path
          d="M17.5497 19.5517C17.8426 19.2588 18.3175 19.2588 18.6104 19.5517L20.7866 21.7279C21.0795 22.0207 21.0795 22.4956 20.7866 22.7885C20.4937 23.0814 20.0188 23.0814 19.7259 22.7885L17.5497 20.6124C17.2569 20.3195 17.2569 19.8446 17.5497 19.5517Z"
          fill="black"
        />
        <path
          d="M4.4043 16.0039C4.4043 15.5897 4.74008 15.2539 5.1543 15.2539H8.23122C8.64543 15.2539 8.98122 15.5897 8.98122 16.0039C8.98122 16.4181 8.64543 16.7539 8.23122 16.7539H5.1543C4.74008 16.7539 4.4043 16.4181 4.4043 16.0039Z"
          fill="black"
        />
        <path
          d="M19.0195 16.0039C19.0195 15.5897 19.3553 15.2539 19.7695 15.2539H22.8465C23.2607 15.2539 23.5965 15.5897 23.5965 16.0039C23.5965 16.4181 23.2607 16.7539 22.8465 16.7539H19.7695C19.3553 16.7539 19.0195 16.4181 19.0195 16.0039Z"
          fill="black"
        />
        <path
          d="M10.4516 19.5517C10.7445 19.8446 10.7445 20.3195 10.4516 20.6124L8.27545 22.7885C7.98255 23.0814 7.50768 23.0814 7.21479 22.7885C6.92189 22.4956 6.92189 22.0207 7.21479 21.7279L9.39094 19.5517C9.68383 19.2588 10.1587 19.2588 10.4516 19.5517Z"
          fill="black"
        />
        <path
          d="M20.7867 9.21586C21.0795 9.5088 21.0794 9.98368 20.7865 10.2765L18.6103 12.4519C18.3174 12.7447 17.8425 12.7447 17.5497 12.4517C17.2568 12.1588 17.2569 11.6839 17.5498 11.3911L19.726 9.21567C20.0189 8.92283 20.4938 8.92291 20.7867 9.21586Z"
          fill="black"
        />
        <path
          d="M53.3046 21.9061C53.4932 21.8788 53.6204 22.0997 53.4884 22.2371C51.8509 23.9402 49.5492 25 47 25C42.0294 25 38 20.9706 38 16C38 11.8773 40.7721 8.40199 44.554 7.33638C44.7373 7.28471 44.8722 7.50109 44.7589 7.65427C43.6535 9.14906 43 10.9982 43 13C43 17.9706 47.0294 22 52 22C52.4431 22 52.8787 21.968 53.3046 21.9061Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
}
