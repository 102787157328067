import type { AnyDocument, BaseDocument } from '../types';

export default ({
  currentDocumentId,
  documents,
  offset,
  fallbackToNearbyIfNoNextDoc = false,
  fallbackToSameDocIfNoNextDoc = false,
}: {
  currentDocumentId: AnyDocument['id'];
  documents: AnyDocument[];
  offset: number;
  fallbackToNearbyIfNoNextDoc?: boolean;
  fallbackToSameDocIfNoNextDoc?: boolean;
}): BaseDocument['id'] | null => {
  const index = documents.findIndex((doc) => doc?.id === currentDocumentId);
  if (index < 0) {
    return null;
  }

  const adjacentIndex = index + offset;

  if (adjacentIndex >= 0 && adjacentIndex < documents.length) {
    return documents[adjacentIndex].id;
  } else if (fallbackToNearbyIfNoNextDoc) {
    const possibleAdjacentIndex = index - offset;
    if (possibleAdjacentIndex >= 0 && possibleAdjacentIndex < documents.length) {
      return documents[possibleAdjacentIndex].id;
    }
  }

  if (fallbackToSameDocIfNoNextDoc) {
    return currentDocumentId;
  }

  return null;
};

export const getAdjacentDocumentIdUsingIds = ({
  currentDocumentId,
  documentIds,
  offset,
  fallbackToNearbyIfNoNextDoc = false,
  fallbackToSameDocIfNoNextDoc = false,
}: {
  currentDocumentId: AnyDocument['id'];
  documentIds: AnyDocument['id'][];
  offset: number;
  fallbackToNearbyIfNoNextDoc?: boolean;
  fallbackToSameDocIfNoNextDoc?: boolean;
}): BaseDocument['id'] | null => {
  const index = documentIds.findIndex((docId) => docId === currentDocumentId);
  if (index < 0) {
    return null;
  }

  const adjacentIndex = index + offset;

  if (adjacentIndex >= 0 && adjacentIndex < documentIds.length) {
    return documentIds[adjacentIndex];
  } else if (fallbackToNearbyIfNoNextDoc) {
    const possibleAdjacentIndex = index - offset;
    if (possibleAdjacentIndex >= 0 && possibleAdjacentIndex < documentIds.length) {
      return documentIds[possibleAdjacentIndex];
    }
  }

  if (fallbackToSameDocIfNoNextDoc) {
    return currentDocumentId;
  }

  return null;
};
