import React from 'react';

import Icon from './Icon';

export default function StrokeToggleHideNavigationMenuIcon({
  className,
  text = 'close',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="3.5" width="16" height="13" rx="2" stroke="black" strokeWidth="1.5" />
        <path d="M6 7.5V12.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
    </Icon>
  );
}
