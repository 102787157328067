import React, { useRef } from 'react';
import ReactJson from 'react-json-view';

import useDatabaseBestIndexHelper from '../../../shared/foreground/utils/useDatabaseBestIndexHelper';
import Button from './Button';
import styles from './DatabaseQueryOptimizer.module.css';
import StandardPage from './StandardPage';
import Textarea from './Textarea';

export default function DatabaseQueryOptimizer() {
  const {
    allDocuments,
    bestIndexResult,
    bestIndexStatus,
    computeBestIndexes,
    firstQueryAsJson,
    numberOfRuns,
    onFirstQueryTextareaChanged,
    onNumberOfRunsFieldChanged,
    onSecondQueryTextareaChanged,
    secondQueryAsJson,
  } = useDatabaseBestIndexHelper();

  const firstQueryTextareaRef = useRef(null);
  const secondQueryTextareaRef = useRef(null);

  return (
    <StandardPage>
      <div className={styles.root}>
        <p>Documents: {allDocuments.length}</p>

        <h2>First query</h2>
        <div className={styles.queryWrapper}>
          <Textarea
            onChange={(event: React.FormEvent<HTMLTextAreaElement> & { target: { value: string } }) => {
              onFirstQueryTextareaChanged(event.target.value);
            }}
            ref={firstQueryTextareaRef}
            value={firstQueryAsJson}
          />
        </div>

        <h2>Second query</h2>
        <div className={styles.queryWrapper}>
          <Textarea
            onChange={(event: React.FormEvent<HTMLTextAreaElement> & { target: { value: string } }) => {
              onSecondQueryTextareaChanged(event.target.value);
            }}
            ref={secondQueryTextareaRef}
            value={secondQueryAsJson}
          />
        </div>

        <label className={styles.runsLabel}>
          Runs {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <input
            autoComplete="off"
            onChange={(event) => onNumberOfRunsFieldChanged(event.target.value)}
            type="number"
            value={numberOfRuns}
          />
        </label>

        <div>
          <Button onClick={computeBestIndexes} variant="primary">
            Compute best indexes
          </Button>
        </div>

        <h2>Best index results</h2>
        <p>Status: {bestIndexStatus}</p>
        <div className={styles.json}>
          <ReactJson collapsed={false} src={bestIndexResult ?? {}} />
        </div>
      </div>
    </StandardPage>
  );
}
