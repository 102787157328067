import { DocumentLocation, FeedDocumentLocation } from '../../types';

export default function listShouldShowDocumentLocation(currentPath: string): boolean {
  const isFeed = Boolean(
    currentPath.match(`/${DocumentLocation.Feed}/(${Object.values(FeedDocumentLocation).join('|')})`),
  );
  const isFilteredViewNotSplitByDocumentLocation =
    currentPath.includes('/filter') && !currentPath.includes('/split/triage_status');
  const isSearch = currentPath.includes('/search');

  return isFeed || isFilteredViewNotSplitByDocumentLocation || isSearch;
}
