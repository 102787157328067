import type { KeyboardApiName, KeyboardShortcutEvent } from '../../../shared/types/keyboardShortcuts';
import getEventTarget from './getEventTarget';

export default function createKeyboardShortcutEvent(
  apiName: KeyboardApiName,
  nativeKeyboardEvent?: KeyboardEvent,
): KeyboardShortcutEvent {
  // Not really necessary but why not
  if (apiName === 'react-hotkeys') {
    if (!nativeKeyboardEvent) {
      throw new Error('Expected a `nativeKeyboardEvent` but did not get one');
    }
  } else if (nativeKeyboardEvent) {
    throw new Error('Expected no `nativeKeyboardEvent` but received one');
  }

  const noOp = () => {};

  return {
    apiName,
    nativeKeyboardEvent,
    preventDefault: nativeKeyboardEvent ? () => nativeKeyboardEvent.preventDefault() : noOp,
    stopImmediatePropagation: nativeKeyboardEvent
      ? () => nativeKeyboardEvent.stopImmediatePropagation()
      : noOp,
    stopPropagation: nativeKeyboardEvent ? () => nativeKeyboardEvent.stopPropagation() : noOp,
    target:
      (nativeKeyboardEvent ? getEventTarget(nativeKeyboardEvent) : document.activeElement) ??
      document.body,
  } as KeyboardShortcutEvent;
}
