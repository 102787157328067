import React from 'react';

import Icon from './Icon';

export default function EmailsIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="EmailsIcon">
      <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.75 3C1.7835 3 1 3.74619 1 4.66667V11.3333C1 12.2538 1.7835 13 2.75 13H13.25C14.2165 13 15 12.2538 15 11.3333V4.66667C15 3.74619 14.2165 3 13.25 3H2.75ZM4.12654 5.23396L7.99976 7.81611L11.873 5.23396C12.2689 4.97003 12.8145 5.06173 13.0916 5.43877C13.3687 5.81581 13.2724 6.33542 12.8765 6.59935L8.50154 9.51602C8.20026 9.71687 7.79926 9.71687 7.49798 9.51602L3.12298 6.59935C2.72709 6.33542 2.63081 5.81581 2.90793 5.43877C3.18506 5.06173 3.73065 4.97003 4.12654 5.23396Z" />
      </svg>
    </Icon>
  );
}
