// eslint-disable-next-line import/no-cycle
import { CancelStateUpdate, updateState } from '../../models';
import { isNarrowScreenSize } from '../../stateHooks';
import type { StateUpdateOptionsWithoutEventName } from '../../types';
import isNotebookViewUrl from '../../utils/isNotebookViewUrl';
import isReaderViewUrl from '../../utils/isReaderViewUrl';

export const toggleHideLeftSidebar = async (
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        state.leftSidebarHiddenForNarrowScreen = !state.leftSidebarHiddenForNarrowScreen;
      } else if (isReaderViewUrl() || isNotebookViewUrl()) {
        state.leftSidebarHiddenInReadingView = !state.leftSidebarHiddenInReadingView;
      }
    },
    { ...options, isUndoable: false, eventName: 'hide-left-sidebar-toggled' },
  );
};

export const hideReaderViewSidebars = async (
  hide = true,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        if (
          state.leftSidebarHiddenForNarrowScreen === hide &&
          state.rightSidebarHiddenForNarrowScreen === hide
        ) {
          throw new CancelStateUpdate();
        }
        state.leftSidebarHiddenForNarrowScreen = hide;
        state.rightSidebarHiddenForNarrowScreen = hide;
      } else {
        if (
          state.leftSidebarHiddenInReadingView === hide &&
          state.rightSidebarHiddenInReadingView === hide
        ) {
          throw new CancelStateUpdate();
        }
        state.leftSidebarHiddenInReadingView = hide;
        state.rightSidebarHiddenInReadingView = hide;
      }
    },
    {
      ...options,
      isUndoable: false,
      eventName: 'hide-sidebars-toggled',
    },
  );
};

export const hideRightReaderViewSidebar = async (
  hide = true,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        state.rightSidebarHiddenForNarrowScreen = hide;
      } else {
        state.rightSidebarHiddenInReadingView = hide;
      }
    },
    { ...options, isUndoable: false, eventName: 'hide-right-sidebar-toggled' },
  );
};

export const hideLeftReaderViewSidebar = async (
  hide = true,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        state.leftSidebarHiddenForNarrowScreen = hide;
      } else {
        state.leftSidebarHiddenInReadingView = hide;
      }
    },
    { ...options, isUndoable: false, eventName: 'hide-left-sidebar-toggled' },
  );
};

export const setShouldRunSidebarItemCounts = async (
  shouldRunSidebarItemCounts: boolean,
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.shouldRunSidebarItemCounts = shouldRunSidebarItemCounts;
    },
    {
      ...(options ?? {}),
      userInteraction: 'unknown',
      isUndoable: false,
      eventName: 'should-run-sidebar-item-counts-changed',
    },
  );
};
