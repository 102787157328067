import React from 'react';

import { getTimeToRead } from '../../../shared/utils/getTimeToRead';
import styles from './TimeLeftToRead.module.css';

type Props = {
  suffix?: string;
  readingPercent: number;
  wordCount: number;
  className?: string;
};

export default function TimeLeftToRead({
  readingPercent,
  suffix = ' left',
  wordCount,
  className = '',
}: Props) {
  let minsLeft = getTimeToRead(wordCount * (1 - readingPercent / 100));
  let hoursText = '';
  if (minsLeft >= 60) {
    const hoursLeft = Math.floor(minsLeft / 60);
    hoursText = `${hoursLeft}hr `;
    minsLeft = minsLeft - hoursLeft * 60;
  }

  return (
    <span className={`${styles.timeLeftToRead} ${className}`}>
      {hoursText}
      {minsLeft}min{suffix}
    </span>
  );
}
