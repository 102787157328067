import { useCallback, useMemo } from 'react';

import { isRssFolder, isRssPinnedItem } from '../../utils/feed';
import { getFoldersByFeedId } from '../utils/getFoldersByFeedId';
import useGlobalStateWithFallback from '../utils/useGlobalStateWithFallback';

export function useRssFoldersAndItems() {
  return useGlobalStateWithFallback(
    [],
    useCallback((state) => state.persistent.rssFoldersAndItems, []),
  );
}

export function useRssItemByFeedId(feedId: string | undefined) {
  const rssFoldersAndItems = useRssFoldersAndItems();

  return useMemo(() => {
    if (!feedId) {
      return undefined;
    }

    for (const folderOrItem of rssFoldersAndItems) {
      if (isRssPinnedItem(folderOrItem) && folderOrItem.rssSourceId === feedId) {
        return folderOrItem;
      } else if (isRssFolder(folderOrItem)) {
        for (const item of folderOrItem.childrenRssItems) {
          if (item.rssSourceId === feedId) {
            return item;
          }
        }
      }
    }
  }, [rssFoldersAndItems, feedId]);
}

export const usePinnedRssFoldersAndItems = () => {
  const rssFoldersAndItems = useRssFoldersAndItems();

  const { rssFolders, pinnedRssItems } = useMemo(() => {
    const folders = rssFoldersAndItems.filter(isRssFolder);
    const pinnedItems = rssFoldersAndItems.filter(isRssPinnedItem);

    return { rssFolders: folders, pinnedRssItems: pinnedItems };
  }, [rssFoldersAndItems]);

  return { rssFolders, pinnedRssItems };
};

export const usePinnedRssItems = () => {
  const { pinnedRssItems } = usePinnedRssFoldersAndItems();
  return pinnedRssItems;
};

export const useRssFolders = () => {
  const { rssFolders } = usePinnedRssFoldersAndItems();
  return rssFolders;
};

export const useAllRssItems = () => {
  const rssFoldersAndItems = useRssFoldersAndItems();

  return useMemo(() => {
    return rssFoldersAndItems.flatMap((folderOrItem) => {
      if (isRssFolder(folderOrItem)) {
        return folderOrItem.childrenRssItems;
      }
      return folderOrItem;
    });
  }, [rssFoldersAndItems]);
};

export const useFoldersByFeedId = () => {
  const rssFolders = useRssFolders();
  return useMemo(() => getFoldersByFeedId(rssFolders), [rssFolders]);
};
