import { useEffect } from 'react';
import type { MangoQuery } from 'rxdb';

import type { AnyDocument } from '../../types';
import { setFocusedDocumentListQuery } from '../stateUpdaters/transientStateUpdaters/lists';

export function useSetFocusedDocumentListQueryInZustand(query: MangoQuery<AnyDocument> | undefined) {
  useEffect(() => {
    if (!query) {
      // this should only happen if parsing into a query fails, and then we have bigger problems.
      return;
    }
    setFocusedDocumentListQuery(query);
    return () => {
      // make sure to unset focused document list query when we unmount document list components.
      setFocusedDocumentListQuery(null);
    };
  }, [query]);
}
