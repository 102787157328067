import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { FAQ_DOC_URL } from '../../../shared/constants.platform';
import { getFaqDocument } from '../../../shared/foreground/utils/getFaqDocument';
import urlJoin from '../../../shared/utils/urlJoin';

export default function FAQRedirect() {
  const history = useHistory();
  useEffect(() => {
    const faqRedirect = async () => {
      const faqDoc = await getFaqDocument();
      if (faqDoc) {
        history.push(urlJoin(['/read', faqDoc.id]));
      } else {
        // If user deleted helpdoc, open original url
        window.location.href = FAQ_DOC_URL;
      }
    };
    faqRedirect();
  }, [history]);
  return null;
}
