import React, { createContext } from 'react';

export interface AdaptiveHeaderContextState {
  headerIsHidden?: boolean;
  setHeaderIsHidden?: React.Dispatch<React.SetStateAction<boolean>>;

  isBorderVisible?: boolean;
}

export const AdaptiveHeaderContext = createContext({} as AdaptiveHeaderContextState);
