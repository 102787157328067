import React from 'react';

import Icon from '../icons/Icon';

export default function EmailsIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="EmailsIcon">
      <svg
        className={className}
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.48016 3.42382C4.16195 3.15865 3.68903 3.20164 3.42385 3.51985C3.15868 3.83806 3.20167 4.31098 3.51988 4.57615L8.8797 9.04267C9.52868 9.58348 10.4714 9.58348 11.1203 9.04267L16.4802 4.57615C16.7984 4.31098 16.8414 3.83806 16.5762 3.51985C16.311 3.20164 15.8381 3.15865 15.5199 3.42382L10.1601 7.89034C10.0674 7.9676 9.93268 7.9676 9.83997 7.89034L4.48016 3.42382Z"
          fill="#A1AAC4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V11C20 13.2091 18.2091 15 16 15H4C1.79086 15 0 13.2091 0 11V4ZM4 1.5H16C17.3807 1.5 18.5 2.61929 18.5 4V11C18.5 12.3807 17.3807 13.5 16 13.5H4C2.61929 13.5 1.5 12.3807 1.5 11V4C1.5 2.61929 2.61929 1.5 4 1.5Z"
          fill="#A1AAC4"
        />
      </svg>
    </Icon>
  );
}
