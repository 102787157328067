import { DocumentLocation } from '../../../shared/types';
import ArchiveIcon from '../components/icons/ArchiveIcon';
import InboxIcon from '../components/icons/InboxIcon';
import LaterIcon from '../components/icons/LaterIcon';
import ShortlistIcon from '../components/icons/ShortlistIcon';

export default {
  [DocumentLocation.Archive]: ArchiveIcon,
  [DocumentLocation.Later]: LaterIcon,
  [DocumentLocation.New]: InboxIcon,
  [DocumentLocation.Shortlist]: ShortlistIcon,
};
