import React from 'react';

import styles from './StandardPage.module.css';

type Props = {
  children: JSX.Element | JSX.Element[];
  isSidebarHidden?: boolean;
};

export default (function StandardPage({ children, isSidebarHidden }) {
  return <div className={styles.page}>{children}</div>;
} as React.FC<Props>);
