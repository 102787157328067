import isFocusableElement from './isFocusableElement';

const isDeepestFocusableElement = (descendant: Element) => {
  // Blockquotes are always focused as a whole
  return (
    isFocusableElement(descendant) &&
    (descendant.tagName.toLowerCase() === 'blockquote' ||
      !Array.from(descendant.children).some(isFocusableElement))
  );
};

export default isDeepestFocusableElement;
